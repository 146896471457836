/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    
 ==========================================================*/
/**
 * 
 * request: getValidationRuleByIdUsingGET
 * url: getValidationRuleByIdUsingGETURL
 * method: getValidationRuleByIdUsingGET_TYPE
 * raw_url: getValidationRuleByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getValidationRuleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getValidationRuleByIdUsingGET_RAW_URL = function() {
  return '/validation-rule/{id}'
}
export const getValidationRuleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getValidationRuleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateValidationRuleUsingPUT
 * url: updateValidationRuleUsingPUTURL
 * method: updateValidationRuleUsingPUT_TYPE
 * raw_url: updateValidationRuleUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateValidationRuleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateValidationRuleUsingPUT_RAW_URL = function() {
  return '/validation-rule/{id}'
}
export const updateValidationRuleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateValidationRuleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteValidationRuleUsingDELETE
 * url: deleteValidationRuleUsingDELETEURL
 * method: deleteValidationRuleUsingDELETE_TYPE
 * raw_url: deleteValidationRuleUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteValidationRuleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteValidationRuleUsingDELETE_RAW_URL = function() {
  return '/validation-rule/{id}'
}
export const deleteValidationRuleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteValidationRuleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateValidationRuleRolesUsingPUT
 * url: updateValidationRuleRolesUsingPUTURL
 * method: updateValidationRuleRolesUsingPUT_TYPE
 * raw_url: updateValidationRuleRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateValidationRuleRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateValidationRuleRolesUsingPUT_RAW_URL = function() {
  return '/validation-rule/{id}/roles'
}
export const updateValidationRuleRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateValidationRuleRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUserByIdUsingGET
 * url: getUserByIdUsingGETURL
 * method: getUserByIdUsingGET_TYPE
 * raw_url: getUserByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getUserByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserByIdUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getUserByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateUserUsingPUT
 * url: updateUserUsingPUTURL
 * method: updateUserUsingPUT_TYPE
 * raw_url: updateUserUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateUserUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateUserUsingPUT_RAW_URL = function() {
  return '/user/{id}'
}
export const updateUserUsingPUT_TYPE = function() {
  return 'put'
}
export const updateUserUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteUserUsingDELETE
 * url: deleteUserUsingDELETEURL
 * method: deleteUserUsingDELETE_TYPE
 * raw_url: deleteUserUsingDELETE_RAW_URL
 * @param id - 
 */
export const deleteUserUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteUserUsingDELETE_RAW_URL = function() {
  return '/user/{id}'
}
export const deleteUserUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteUserUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: generateApiKeyUsingPUT
 * url: generateApiKeyUsingPUTURL
 * method: generateApiKeyUsingPUT_TYPE
 * raw_url: generateApiKeyUsingPUT_RAW_URL
 * @param id - 
 */
export const generateApiKeyUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/generate-api-key'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const generateApiKeyUsingPUT_RAW_URL = function() {
  return '/user/{id}/generate-api-key'
}
export const generateApiKeyUsingPUT_TYPE = function() {
  return 'put'
}
export const generateApiKeyUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/generate-api-key'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: resetFailedAuthAttemptsUsingPUT
 * url: resetFailedAuthAttemptsUsingPUTURL
 * method: resetFailedAuthAttemptsUsingPUT_TYPE
 * raw_url: resetFailedAuthAttemptsUsingPUT_RAW_URL
 * @param id - 
 */
export const resetFailedAuthAttemptsUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/failed-auth-attempts/reset'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const resetFailedAuthAttemptsUsingPUT_RAW_URL = function() {
  return '/user/{id}/failed-auth-attempts/reset'
}
export const resetFailedAuthAttemptsUsingPUT_TYPE = function() {
  return 'put'
}
export const resetFailedAuthAttemptsUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/failed-auth-attempts/reset'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerRestByIdUsingGET
 * url: getTriggerRestByIdUsingGETURL
 * method: getTriggerRestByIdUsingGET_TYPE
 * raw_url: getTriggerRestByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getTriggerRestByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerRestByIdUsingGET_RAW_URL = function() {
  return '/trigger-rest/{id}'
}
export const getTriggerRestByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerRestByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerRestUsingPUT
 * url: updateTriggerRestUsingPUTURL
 * method: updateTriggerRestUsingPUT_TYPE
 * raw_url: updateTriggerRestUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerRestUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerRestUsingPUT_RAW_URL = function() {
  return '/trigger-rest/{id}'
}
export const updateTriggerRestUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerRestUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteRestTriggerUsingDELETE
 * url: deleteRestTriggerUsingDELETEURL
 * method: deleteRestTriggerUsingDELETE_TYPE
 * raw_url: deleteRestTriggerUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteRestTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRestTriggerUsingDELETE_RAW_URL = function() {
  return '/trigger-rest/{id}'
}
export const deleteRestTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRestTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerRestRolesUsingPUT
 * url: updateTriggerRestRolesUsingPUTURL
 * method: updateTriggerRestRolesUsingPUT_TYPE
 * raw_url: updateTriggerRestRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerRestRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerRestRolesUsingPUT_RAW_URL = function() {
  return '/trigger-rest/{id}/roles'
}
export const updateTriggerRestRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerRestRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerMessagingByIdUsingGET
 * url: getTriggerMessagingByIdUsingGETURL
 * method: getTriggerMessagingByIdUsingGET_TYPE
 * raw_url: getTriggerMessagingByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getTriggerMessagingByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerMessagingByIdUsingGET_RAW_URL = function() {
  return '/trigger-messaging/{id}'
}
export const getTriggerMessagingByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerMessagingByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerMessagingUsingPUT
 * url: updateTriggerMessagingUsingPUTURL
 * method: updateTriggerMessagingUsingPUT_TYPE
 * raw_url: updateTriggerMessagingUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerMessagingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerMessagingUsingPUT_RAW_URL = function() {
  return '/trigger-messaging/{id}'
}
export const updateTriggerMessagingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerMessagingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteMessagingTriggerUsingDELETE
 * url: deleteMessagingTriggerUsingDELETEURL
 * method: deleteMessagingTriggerUsingDELETE_TYPE
 * raw_url: deleteMessagingTriggerUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteMessagingTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMessagingTriggerUsingDELETE_RAW_URL = function() {
  return '/trigger-messaging/{id}'
}
export const deleteMessagingTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMessagingTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerMessagingRolesUsingPUT
 * url: updateTriggerMessagingRolesUsingPUTURL
 * method: updateTriggerMessagingRolesUsingPUT_TYPE
 * raw_url: updateTriggerMessagingRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerMessagingRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerMessagingRolesUsingPUT_RAW_URL = function() {
  return '/trigger-messaging/{id}/roles'
}
export const updateTriggerMessagingRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerMessagingRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerEventHandlerByIdUsingGET
 * url: getTriggerEventHandlerByIdUsingGETURL
 * method: getTriggerEventHandlerByIdUsingGET_TYPE
 * raw_url: getTriggerEventHandlerByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getTriggerEventHandlerByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerEventHandlerByIdUsingGET_RAW_URL = function() {
  return '/trigger-event-handler/{id}'
}
export const getTriggerEventHandlerByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerEventHandlerByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerEventHandlerUsingPUT
 * url: updateTriggerEventHandlerUsingPUTURL
 * method: updateTriggerEventHandlerUsingPUT_TYPE
 * raw_url: updateTriggerEventHandlerUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerEventHandlerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerEventHandlerUsingPUT_RAW_URL = function() {
  return '/trigger-event-handler/{id}'
}
export const updateTriggerEventHandlerUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerEventHandlerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTriggerEventHandlerUsingDELETE
 * url: deleteTriggerEventHandlerUsingDELETEURL
 * method: deleteTriggerEventHandlerUsingDELETE_TYPE
 * raw_url: deleteTriggerEventHandlerUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteTriggerEventHandlerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTriggerEventHandlerUsingDELETE_RAW_URL = function() {
  return '/trigger-event-handler/{id}'
}
export const deleteTriggerEventHandlerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTriggerEventHandlerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerEventHandlerRolesUsingPUT
 * url: updateTriggerEventHandlerRolesUsingPUTURL
 * method: updateTriggerEventHandlerRolesUsingPUT_TYPE
 * raw_url: updateTriggerEventHandlerRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerEventHandlerRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerEventHandlerRolesUsingPUT_RAW_URL = function() {
  return '/trigger-event-handler/{id}/roles'
}
export const updateTriggerEventHandlerRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerEventHandlerRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerCronByIdUsingGET
 * url: getTriggerCronByIdUsingGETURL
 * method: getTriggerCronByIdUsingGET_TYPE
 * raw_url: getTriggerCronByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getTriggerCronByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerCronByIdUsingGET_RAW_URL = function() {
  return '/trigger-cron/{id}'
}
export const getTriggerCronByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerCronByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerCronUsingPUT
 * url: updateTriggerCronUsingPUTURL
 * method: updateTriggerCronUsingPUT_TYPE
 * raw_url: updateTriggerCronUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerCronUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerCronUsingPUT_RAW_URL = function() {
  return '/trigger-cron/{id}'
}
export const updateTriggerCronUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerCronUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteCronTriggerUsingDELETE
 * url: deleteCronTriggerUsingDELETEURL
 * method: deleteCronTriggerUsingDELETE_TYPE
 * raw_url: deleteCronTriggerUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteCronTriggerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCronTriggerUsingDELETE_RAW_URL = function() {
  return '/trigger-cron/{id}'
}
export const deleteCronTriggerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCronTriggerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTriggerCronRolesUsingPUT
 * url: updateTriggerCronRolesUsingPUTURL
 * method: updateTriggerCronRolesUsingPUT_TYPE
 * raw_url: updateTriggerCronRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTriggerCronRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTriggerCronRolesUsingPUT_RAW_URL = function() {
  return '/trigger-cron/{id}/roles'
}
export const updateTriggerCronRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTriggerCronRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTranslationUsingPUT
 * url: updateTranslationUsingPUTURL
 * method: updateTranslationUsingPUT_TYPE
 * raw_url: updateTranslationUsingPUT_RAW_URL
 * @param translationId - 
 * @param body - 
 */
export const updateTranslationUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTranslationUsingPUT_RAW_URL = function() {
  return '/translation/{translationId}'
}
export const updateTranslationUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTranslationUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{translationId}'
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTranslationUsingDELETE
 * url: deleteTranslationUsingDELETEURL
 * method: deleteTranslationUsingDELETE_TYPE
 * raw_url: deleteTranslationUsingDELETE_RAW_URL
 * @param translationId - 
 * @param force - 
 */
export const deleteTranslationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTranslationUsingDELETE_RAW_URL = function() {
  return '/translation/{translationId}'
}
export const deleteTranslationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTranslationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{translationId}'
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTranslationRolesUsingPUT
 * url: updateTranslationRolesUsingPUTURL
 * method: updateTranslationRolesUsingPUT_TYPE
 * raw_url: updateTranslationRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTranslationRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTranslationRolesUsingPUT_RAW_URL = function() {
  return '/translation/{id}/roles'
}
export const updateTranslationRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTranslationRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTextTemplateByIdUsingGET
 * url: getTextTemplateByIdUsingGETURL
 * method: getTextTemplateByIdUsingGET_TYPE
 * raw_url: getTextTemplateByIdUsingGET_RAW_URL
 * @param templateId - 
 */
export const getTextTemplateByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateByIdUsingGET_RAW_URL = function() {
  return '/text-template/{templateId}'
}
export const getTextTemplateByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTextTemplateUsingPUT
 * url: updateTextTemplateUsingPUTURL
 * method: updateTextTemplateUsingPUT_TYPE
 * raw_url: updateTextTemplateUsingPUT_RAW_URL
 * @param templateId - 
 * @param body - 
 */
export const updateTextTemplateUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateUsingPUT_RAW_URL = function() {
  return '/text-template/{templateId}'
}
export const updateTextTemplateUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTextTemplateUsingDELETE
 * url: deleteTextTemplateUsingDELETEURL
 * method: deleteTextTemplateUsingDELETE_TYPE
 * raw_url: deleteTextTemplateUsingDELETE_RAW_URL
 * @param templateId - 
 * @param force - 
 */
export const deleteTextTemplateUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTextTemplateUsingDELETE_RAW_URL = function() {
  return '/text-template/{templateId}'
}
export const deleteTextTemplateUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTextTemplateUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTextTemplateTranslationUsingGET
 * url: getTextTemplateTranslationUsingGETURL
 * method: getTextTemplateTranslationUsingGET_TYPE
 * raw_url: getTextTemplateTranslationUsingGET_RAW_URL
 * @param templateId - 
 * @param translationId - 
 */
export const getTextTemplateTranslationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateTranslationUsingGET_RAW_URL = function() {
  return '/text-template/{templateId}/translation/{translationId}'
}
export const getTextTemplateTranslationUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateTranslationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTextTemplateTranslationUsingPUT
 * url: updateTextTemplateTranslationUsingPUTURL
 * method: updateTextTemplateTranslationUsingPUT_TYPE
 * raw_url: updateTextTemplateTranslationUsingPUT_RAW_URL
 * @param templateId - 
 * @param translationId - 
 * @param body - 
 */
export const updateTextTemplateTranslationUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateTranslationUsingPUT_RAW_URL = function() {
  return '/text-template/{templateId}/translation/{translationId}'
}
export const updateTextTemplateTranslationUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateTranslationUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteTextTemplateTranslationUsingDELETE
 * url: deleteTextTemplateTranslationUsingDELETEURL
 * method: deleteTextTemplateTranslationUsingDELETE_TYPE
 * raw_url: deleteTextTemplateTranslationUsingDELETE_RAW_URL
 * @param templateId - 
 * @param translationId - 
 */
export const deleteTextTemplateTranslationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}/translation/{translationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters['translationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: translationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTextTemplateTranslationUsingDELETE_RAW_URL = function() {
  return '/text-template/{templateId}/translation/{translationId}'
}
export const deleteTextTemplateTranslationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTextTemplateTranslationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}/translation/{translationId}'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  path = path.replace('{translationId}', `${parameters['translationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTextTemplateRolesUsingPUT
 * url: updateTextTemplateRolesUsingPUTURL
 * method: updateTextTemplateRolesUsingPUT_TYPE
 * raw_url: updateTextTemplateRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTextTemplateRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTextTemplateRolesUsingPUT_RAW_URL = function() {
  return '/text-template/{id}/roles'
}
export const updateTextTemplateRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTextTemplateRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getStorageByIdUsingGET
 * url: getStorageByIdUsingGETURL
 * method: getStorageByIdUsingGET_TYPE
 * raw_url: getStorageByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getStorageByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storage/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStorageByIdUsingGET_RAW_URL = function() {
  return '/storage/{id}'
}
export const getStorageByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getStorageByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storage/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateStorageUsingPUT
 * url: updateStorageUsingPUTURL
 * method: updateStorageUsingPUT_TYPE
 * raw_url: updateStorageUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateStorageUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storage/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateStorageUsingPUT_RAW_URL = function() {
  return '/storage/{id}'
}
export const updateStorageUsingPUT_TYPE = function() {
  return 'put'
}
export const updateStorageUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storage/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteStorageUsingDELETE
 * url: deleteStorageUsingDELETEURL
 * method: deleteStorageUsingDELETE_TYPE
 * raw_url: deleteStorageUsingDELETE_RAW_URL
 * @param id - 
 */
export const deleteStorageUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storage/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteStorageUsingDELETE_RAW_URL = function() {
  return '/storage/{id}'
}
export const deleteStorageUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteStorageUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storage/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSettingRolesUsingPUT
 * url: updateSettingRolesUsingPUTURL
 * method: updateSettingRolesUsingPUT_TYPE
 * raw_url: updateSettingRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSettingRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSettingRolesUsingPUT_RAW_URL = function() {
  return '/setting/{id}/roles'
}
export const updateSettingRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSettingRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProcessSettingUsingPUT
 * url: updateProcessSettingUsingPUTURL
 * method: updateProcessSettingUsingPUT_TYPE
 * raw_url: updateProcessSettingUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateProcessSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessSettingUsingPUT_RAW_URL = function() {
  return '/setting/process/{id}'
}
export const updateProcessSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePluginSettingUsingPUT
 * url: updatePluginSettingUsingPUTURL
 * method: updatePluginSettingUsingPUT_TYPE
 * raw_url: updatePluginSettingUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePluginSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginSettingUsingPUT_RAW_URL = function() {
  return '/setting/plugin/{id}'
}
export const updatePluginSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateInstanceProcessingSettingUsingPUT
 * url: updateInstanceProcessingSettingUsingPUTURL
 * method: updateInstanceProcessingSettingUsingPUT_TYPE
 * raw_url: updateInstanceProcessingSettingUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateInstanceProcessingSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/instance-processing/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateInstanceProcessingSettingUsingPUT_RAW_URL = function() {
  return '/setting/instance-processing/{id}'
}
export const updateInstanceProcessingSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateInstanceProcessingSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/instance-processing/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateInstanceAdminSettingUsingPUT
 * url: updateInstanceAdminSettingUsingPUTURL
 * method: updateInstanceAdminSettingUsingPUT_TYPE
 * raw_url: updateInstanceAdminSettingUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateInstanceAdminSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/instance-admin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateInstanceAdminSettingUsingPUT_RAW_URL = function() {
  return '/setting/instance-admin/{id}'
}
export const updateInstanceAdminSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateInstanceAdminSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/instance-admin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getRoleByIdUsingGET
 * url: getRoleByIdUsingGETURL
 * method: getRoleByIdUsingGET_TYPE
 * raw_url: getRoleByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getRoleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRoleByIdUsingGET_RAW_URL = function() {
  return '/role/{id}'
}
export const getRoleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getRoleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateRoleUsingPUT
 * url: updateRoleUsingPUTURL
 * method: updateRoleUsingPUT_TYPE
 * raw_url: updateRoleUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateRoleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRoleUsingPUT_RAW_URL = function() {
  return '/role/{id}'
}
export const updateRoleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRoleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteRoleUsingDELETE
 * url: deleteRoleUsingDELETEURL
 * method: deleteRoleUsingDELETE_TYPE
 * raw_url: deleteRoleUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteRoleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRoleUsingDELETE_RAW_URL = function() {
  return '/role/{id}'
}
export const deleteRoleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRoleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessByIdUsingGET
 * url: getProcessByIdUsingGETURL
 * method: getProcessByIdUsingGET_TYPE
 * raw_url: getProcessByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessByIdUsingGET_RAW_URL = function() {
  return '/process/{id}'
}
export const getProcessByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProcessUsingPUT
 * url: updateProcessUsingPUTURL
 * method: updateProcessUsingPUT_TYPE
 * raw_url: updateProcessUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateProcessUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessUsingPUT_RAW_URL = function() {
  return '/process/{id}'
}
export const updateProcessUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteProcessUsingDELETE
 * url: deleteProcessUsingDELETEURL
 * method: deleteProcessUsingDELETE_TYPE
 * raw_url: deleteProcessUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteProcessUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProcessUsingDELETE_RAW_URL = function() {
  return '/process/{id}'
}
export const deleteProcessUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteProcessUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProcessRolesUsingPUT
 * url: updateProcessRolesUsingPUTURL
 * method: updateProcessRolesUsingPUT_TYPE
 * raw_url: updateProcessRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateProcessRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessRolesUsingPUT_RAW_URL = function() {
  return '/process/{id}/roles'
}
export const updateProcessRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProcessCredentialRolesUsingPUT
 * url: updateProcessCredentialRolesUsingPUTURL
 * method: updateProcessCredentialRolesUsingPUT_TYPE
 * raw_url: updateProcessCredentialRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateProcessCredentialRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessCredentialRolesUsingPUT_RAW_URL = function() {
  return '/process-credential/{id}/roles'
}
export const updateProcessCredentialRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessCredentialRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateTwilioProcessCredentialUsingPUT
 * url: updateTwilioProcessCredentialUsingPUTURL
 * method: updateTwilioProcessCredentialUsingPUT_TYPE
 * raw_url: updateTwilioProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateTwilioProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/twilio/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateTwilioProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/twilio/{id}'
}
export const updateTwilioProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateTwilioProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/twilio/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSshCredentialUsingPUT
 * url: updateSshCredentialUsingPUTURL
 * method: updateSshCredentialUsingPUT_TYPE
 * raw_url: updateSshCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSshCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ssh/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSshCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/ssh/{id}'
}
export const updateSshCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSshCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ssh/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSshKeyCredentialUsingPUT
 * url: updateSshKeyCredentialUsingPUTURL
 * method: updateSshKeyCredentialUsingPUT_TYPE
 * raw_url: updateSshKeyCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSshKeyCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ssh-key/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSshKeyCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/ssh-key/{id}'
}
export const updateSshKeyCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSshKeyCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ssh-key/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSmtpProcessCredentialUsingPUT
 * url: updateSmtpProcessCredentialUsingPUTURL
 * method: updateSmtpProcessCredentialUsingPUT_TYPE
 * raw_url: updateSmtpProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSmtpProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/smtp/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSmtpProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/smtp/{id}'
}
export const updateSmtpProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSmtpProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/smtp/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSlackProcessCredentialUsingPUT
 * url: updateSlackProcessCredentialUsingPUTURL
 * method: updateSlackProcessCredentialUsingPUT_TYPE
 * raw_url: updateSlackProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSlackProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/slack/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSlackProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/slack/{id}'
}
export const updateSlackProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSlackProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/slack/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSftpProcessCredentialUsingPUT
 * url: updateSftpProcessCredentialUsingPUTURL
 * method: updateSftpProcessCredentialUsingPUT_TYPE
 * raw_url: updateSftpProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSftpProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/sftp/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSftpProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/sftp/{id}'
}
export const updateSftpProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSftpProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/sftp/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateSecurityCredentialUsingPUT
 * url: updateSecurityCredentialUsingPUTURL
 * method: updateSecurityCredentialUsingPUT_TYPE
 * raw_url: updateSecurityCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateSecurityCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/security/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateSecurityCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/security/{id}'
}
export const updateSecurityCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateSecurityCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/security/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateScriptCredentialUsingPUT
 * url: updateScriptCredentialUsingPUTURL
 * method: updateScriptCredentialUsingPUT_TYPE
 * raw_url: updateScriptCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateScriptCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/script/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateScriptCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/script/{id}'
}
export const updateScriptCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateScriptCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/script/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateRestCredentialUsingPUT
 * url: updateRestCredentialUsingPUTURL
 * method: updateRestCredentialUsingPUT_TYPE
 * raw_url: updateRestCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateRestCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRestCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/rest/{id}'
}
export const updateRestCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRestCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateRestBearerCredentialUsingPUT
 * url: updateRestBearerCredentialUsingPUTURL
 * method: updateRestBearerCredentialUsingPUT_TYPE
 * raw_url: updateRestBearerCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateRestBearerCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest-bearer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRestBearerCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/rest-bearer/{id}'
}
export const updateRestBearerCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRestBearerCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest-bearer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateRestBasicCredentialUsingPUT
 * url: updateRestBasicCredentialUsingPUTURL
 * method: updateRestBasicCredentialUsingPUT_TYPE
 * raw_url: updateRestBasicCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateRestBasicCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest-basic/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRestBasicCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/rest-basic/{id}'
}
export const updateRestBasicCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRestBasicCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest-basic/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateRabbitmqCredentialUsingPUT
 * url: updateRabbitmqCredentialUsingPUTURL
 * method: updateRabbitmqCredentialUsingPUT_TYPE
 * raw_url: updateRabbitmqCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateRabbitmqCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rabbitmq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRabbitmqCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/rabbitmq/{id}'
}
export const updateRabbitmqCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRabbitmqCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rabbitmq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePluginCredentialUsingPUT
 * url: updatePluginCredentialUsingPUTURL
 * method: updatePluginCredentialUsingPUT_TYPE
 * raw_url: updatePluginCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePluginCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/plugin/{id}'
}
export const updatePluginCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePaymentSensePacCredentialUsingPUT
 * url: updatePaymentSensePacCredentialUsingPUTURL
 * method: updatePaymentSensePacCredentialUsingPUT_TYPE
 * raw_url: updatePaymentSensePacCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePaymentSensePacCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/payment-sense-pac/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePaymentSensePacCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/payment-sense-pac/{id}'
}
export const updatePaymentSensePacCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePaymentSensePacCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/payment-sense-pac/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePaymentSenseConnectECredentialUsingPUT
 * url: updatePaymentSenseConnectECredentialUsingPUTURL
 * method: updatePaymentSenseConnectECredentialUsingPUT_TYPE
 * raw_url: updatePaymentSenseConnectECredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePaymentSenseConnectECredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/payment-sense-connect-e/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePaymentSenseConnectECredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/payment-sense-connect-e/{id}'
}
export const updatePaymentSenseConnectECredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePaymentSenseConnectECredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/payment-sense-connect-e/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateOutlookCalendarCredentialUsingPUT
 * url: updateOutlookCalendarCredentialUsingPUTURL
 * method: updateOutlookCalendarCredentialUsingPUT_TYPE
 * raw_url: updateOutlookCalendarCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateOutlookCalendarCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/outlook-calendar/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateOutlookCalendarCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/outlook-calendar/{id}'
}
export const updateOutlookCalendarCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateOutlookCalendarCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/outlook-calendar/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateOpenAIProcessCredentialUsingPUT
 * url: updateOpenAIProcessCredentialUsingPUTURL
 * method: updateOpenAIProcessCredentialUsingPUT_TYPE
 * raw_url: updateOpenAIProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateOpenAIProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/open-ai/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateOpenAIProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/open-ai/{id}'
}
export const updateOpenAIProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateOpenAIProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/open-ai/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateMqttCredentialUsingPUT
 * url: updateMqttCredentialUsingPUTURL
 * method: updateMqttCredentialUsingPUT_TYPE
 * raw_url: updateMqttCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateMqttCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/mqtt/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateMqttCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/mqtt/{id}'
}
export const updateMqttCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateMqttCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/mqtt/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateMongodbProcessCredentialUsingPUT
 * url: updateMongodbProcessCredentialUsingPUTURL
 * method: updateMongodbProcessCredentialUsingPUT_TYPE
 * raw_url: updateMongodbProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateMongodbProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/mongodb/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateMongodbProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/mongodb/{id}'
}
export const updateMongodbProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateMongodbProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/mongodb/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateLdapCredentialUsingPUT
 * url: updateLdapCredentialUsingPUTURL
 * method: updateLdapCredentialUsingPUT_TYPE
 * raw_url: updateLdapCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateLdapCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ldap/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateLdapCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/ldap/{id}'
}
export const updateLdapCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateLdapCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ldap/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateKafkaCredentialUsingPUT
 * url: updateKafkaCredentialUsingPUTURL
 * method: updateKafkaCredentialUsingPUT_TYPE
 * raw_url: updateKafkaCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateKafkaCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/kafka/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateKafkaCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/kafka/{id}'
}
export const updateKafkaCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateKafkaCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/kafka/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateJwtProcessCredentialUsingPUT
 * url: updateJwtProcessCredentialUsingPUTURL
 * method: updateJwtProcessCredentialUsingPUT_TYPE
 * raw_url: updateJwtProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateJwtProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jwt/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJwtProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/jwt/{id}'
}
export const updateJwtProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJwtProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jwt/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateJmsCredentialUsingPUT
 * url: updateJmsCredentialUsingPUTURL
 * method: updateJmsCredentialUsingPUT_TYPE
 * raw_url: updateJmsCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateJmsCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jms/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJmsCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/jms/{id}'
}
export const updateJmsCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJmsCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jms/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateJdbcProcessCredentialUsingPUT
 * url: updateJdbcProcessCredentialUsingPUTURL
 * method: updateJdbcProcessCredentialUsingPUT_TYPE
 * raw_url: updateJdbcProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateJdbcProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jdbc/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateJdbcProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/jdbc/{id}'
}
export const updateJdbcProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateJdbcProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jdbc/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateImapProcessCredentialUsingPUT
 * url: updateImapProcessCredentialUsingPUTURL
 * method: updateImapProcessCredentialUsingPUT_TYPE
 * raw_url: updateImapProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateImapProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/imap/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateImapProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/imap/{id}'
}
export const updateImapProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateImapProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/imap/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateFtpProcessCredentialUsingPUT
 * url: updateFtpProcessCredentialUsingPUTURL
 * method: updateFtpProcessCredentialUsingPUT_TYPE
 * raw_url: updateFtpProcessCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateFtpProcessCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ftp/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateFtpProcessCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/ftp/{id}'
}
export const updateFtpProcessCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateFtpProcessCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ftp/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateFlowyCredentialUsingPUT
 * url: updateFlowyCredentialUsingPUTURL
 * method: updateFlowyCredentialUsingPUT_TYPE
 * raw_url: updateFlowyCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateFlowyCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/flowy/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateFlowyCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/flowy/{id}'
}
export const updateFlowyCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateFlowyCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/flowy/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateAwsCredentialUsingPUT
 * url: updateAwsCredentialUsingPUTURL
 * method: updateAwsCredentialUsingPUT_TYPE
 * raw_url: updateAwsCredentialUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateAwsCredentialUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/aws/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateAwsCredentialUsingPUT_RAW_URL = function() {
  return '/process-credential/aws/{id}'
}
export const updateAwsCredentialUsingPUT_TYPE = function() {
  return 'put'
}
export const updateAwsCredentialUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/aws/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getPluginByIdUsingGET
 * url: getPluginByIdUsingGETURL
 * method: getPluginByIdUsingGET_TYPE
 * raw_url: getPluginByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getPluginByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPluginByIdUsingGET_RAW_URL = function() {
  return '/plugin/{id}'
}
export const getPluginByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getPluginByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePluginUsingPUT
 * url: updatePluginUsingPUTURL
 * method: updatePluginUsingPUT_TYPE
 * raw_url: updatePluginUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePluginUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginUsingPUT_RAW_URL = function() {
  return '/plugin/{id}'
}
export const updatePluginUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deletePluginUsingDELETE
 * url: deletePluginUsingDELETEURL
 * method: deletePluginUsingDELETE_TYPE
 * raw_url: deletePluginUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deletePluginUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePluginUsingDELETE_RAW_URL = function() {
  return '/plugin/{id}'
}
export const deletePluginUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePluginUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePluginRolesUsingPUT
 * url: updatePluginRolesUsingPUTURL
 * method: updatePluginRolesUsingPUT_TYPE
 * raw_url: updatePluginRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updatePluginRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePluginRolesUsingPUT_RAW_URL = function() {
  return '/plugin/{id}/roles'
}
export const updatePluginRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePluginRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updatePasswordUsingPUT
 * url: updatePasswordUsingPUTURL
 * method: updatePasswordUsingPUT_TYPE
 * raw_url: updatePasswordUsingPUT_RAW_URL
 * @param body - 
 */
export const updatePasswordUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updatePasswordUsingPUT_RAW_URL = function() {
  return '/password'
}
export const updatePasswordUsingPUT_TYPE = function() {
  return 'put'
}
export const updatePasswordUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getModuleByIdUsingGET
 * url: getModuleByIdUsingGETURL
 * method: getModuleByIdUsingGET_TYPE
 * raw_url: getModuleByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getModuleByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getModuleByIdUsingGET_RAW_URL = function() {
  return '/module/{id}'
}
export const getModuleByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getModuleByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateModuleUsingPUT
 * url: updateModuleUsingPUTURL
 * method: updateModuleUsingPUT_TYPE
 * raw_url: updateModuleUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateModuleUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateModuleUsingPUT_RAW_URL = function() {
  return '/module/{id}'
}
export const updateModuleUsingPUT_TYPE = function() {
  return 'put'
}
export const updateModuleUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteModuleUsingDELETE
 * url: deleteModuleUsingDELETEURL
 * method: deleteModuleUsingDELETE_TYPE
 * raw_url: deleteModuleUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteModuleUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteModuleUsingDELETE_RAW_URL = function() {
  return '/module/{id}'
}
export const deleteModuleUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteModuleUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateModuleRolesUsingPUT
 * url: updateModuleRolesUsingPUTURL
 * method: updateModuleRolesUsingPUT_TYPE
 * raw_url: updateModuleRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateModuleRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateModuleRolesUsingPUT_RAW_URL = function() {
  return '/module/{id}/roles'
}
export const updateModuleRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateModuleRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getLibraryByIdUsingGET
 * url: getLibraryByIdUsingGETURL
 * method: getLibraryByIdUsingGET_TYPE
 * raw_url: getLibraryByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getLibraryByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLibraryByIdUsingGET_RAW_URL = function() {
  return '/library/{id}'
}
export const getLibraryByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getLibraryByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateLibraryUsingPUT
 * url: updateLibraryUsingPUTURL
 * method: updateLibraryUsingPUT_TYPE
 * raw_url: updateLibraryUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateLibraryUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateLibraryUsingPUT_RAW_URL = function() {
  return '/library/{id}'
}
export const updateLibraryUsingPUT_TYPE = function() {
  return 'put'
}
export const updateLibraryUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteLibraryUsingDELETE
 * url: deleteLibraryUsingDELETEURL
 * method: deleteLibraryUsingDELETE_TYPE
 * raw_url: deleteLibraryUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteLibraryUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteLibraryUsingDELETE_RAW_URL = function() {
  return '/library/{id}'
}
export const deleteLibraryUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteLibraryUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateLibraryRolesUsingPUT
 * url: updateLibraryRolesUsingPUTURL
 * method: updateLibraryRolesUsingPUT_TYPE
 * raw_url: updateLibraryRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateLibraryRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateLibraryRolesUsingPUT_RAW_URL = function() {
  return '/library/{id}/roles'
}
export const updateLibraryRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateLibraryRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateProcessingGlobalSettingUsingPUT
 * url: updateProcessingGlobalSettingUsingPUTURL
 * method: updateProcessingGlobalSettingUsingPUT_TYPE
 * raw_url: updateProcessingGlobalSettingUsingPUT_RAW_URL
 * @param body - 
 */
export const updateProcessingGlobalSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting/processing'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateProcessingGlobalSettingUsingPUT_RAW_URL = function() {
  return '/global-setting/processing'
}
export const updateProcessingGlobalSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateProcessingGlobalSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting/processing'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateFrontendGlobalSettingUsingPUT
 * url: updateFrontendGlobalSettingUsingPUTURL
 * method: updateFrontendGlobalSettingUsingPUT_TYPE
 * raw_url: updateFrontendGlobalSettingUsingPUT_RAW_URL
 * @param body - 
 */
export const updateFrontendGlobalSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting/frontend'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateFrontendGlobalSettingUsingPUT_RAW_URL = function() {
  return '/global-setting/frontend'
}
export const updateFrontendGlobalSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateFrontendGlobalSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting/frontend'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateAdminGlobalSettingUsingPUT
 * url: updateAdminGlobalSettingUsingPUTURL
 * method: updateAdminGlobalSettingUsingPUT_TYPE
 * raw_url: updateAdminGlobalSettingUsingPUT_RAW_URL
 * @param body - 
 */
export const updateAdminGlobalSettingUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting/admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateAdminGlobalSettingUsingPUT_RAW_URL = function() {
  return '/global-setting/admin'
}
export const updateAdminGlobalSettingUsingPUT_TYPE = function() {
  return 'put'
}
export const updateAdminGlobalSettingUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting/admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEntityByIdUsingGET
 * url: getEntityByIdUsingGETURL
 * method: getEntityByIdUsingGET_TYPE
 * raw_url: getEntityByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getEntityByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEntityByIdUsingGET_RAW_URL = function() {
  return '/entity/{id}'
}
export const getEntityByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getEntityByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateEntityUsingPUT
 * url: updateEntityUsingPUTURL
 * method: updateEntityUsingPUT_TYPE
 * raw_url: updateEntityUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateEntityUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateEntityUsingPUT_RAW_URL = function() {
  return '/entity/{id}'
}
export const updateEntityUsingPUT_TYPE = function() {
  return 'put'
}
export const updateEntityUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteEntityUsingDELETE
 * url: deleteEntityUsingDELETEURL
 * method: deleteEntityUsingDELETE_TYPE
 * raw_url: deleteEntityUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteEntityUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEntityUsingDELETE_RAW_URL = function() {
  return '/entity/{id}'
}
export const deleteEntityUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEntityUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: updateEntityRolesUsingPUT
 * url: updateEntityRolesUsingPUTURL
 * method: updateEntityRolesUsingPUT_TYPE
 * raw_url: updateEntityRolesUsingPUT_RAW_URL
 * @param id - 
 * @param body - 
 */
export const updateEntityRolesUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/roles'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateEntityRolesUsingPUT_RAW_URL = function() {
  return '/entity/{id}/roles'
}
export const updateEntityRolesUsingPUT_TYPE = function() {
  return 'put'
}
export const updateEntityRolesUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/roles'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityPersistenceExecuteUpdateUsingPUT
 * url: entityPersistenceExecuteUpdateUsingPUTURL
 * method: entityPersistenceExecuteUpdateUsingPUT_TYPE
 * raw_url: entityPersistenceExecuteUpdateUsingPUT_RAW_URL
 * @param id - 
 */
export const entityPersistenceExecuteUpdateUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/persistence/update/execute'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const entityPersistenceExecuteUpdateUsingPUT_RAW_URL = function() {
  return '/entity/{id}/persistence/update/execute'
}
export const entityPersistenceExecuteUpdateUsingPUT_TYPE = function() {
  return 'put'
}
export const entityPersistenceExecuteUpdateUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/persistence/update/execute'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getValidationRulesUsingGET
 * url: getValidationRulesUsingGETURL
 * method: getValidationRulesUsingGET_TYPE
 * raw_url: getValidationRulesUsingGET_RAW_URL
 * @param isSystem - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getValidationRulesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getValidationRulesUsingGET_RAW_URL = function() {
  return '/validation-rule'
}
export const getValidationRulesUsingGET_TYPE = function() {
  return 'get'
}
export const getValidationRulesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule'
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createValidationRuleUsingPOST
 * url: createValidationRuleUsingPOSTURL
 * method: createValidationRuleUsingPOST_TYPE
 * raw_url: createValidationRuleUsingPOST_RAW_URL
 * @param body - 
 */
export const createValidationRuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/validation-rule'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createValidationRuleUsingPOST_RAW_URL = function() {
  return '/validation-rule'
}
export const createValidationRuleUsingPOST_TYPE = function() {
  return 'post'
}
export const createValidationRuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/validation-rule'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUsersUsingGET
 * url: getUsersUsingGETURL
 * method: getUsersUsingGET_TYPE
 * raw_url: getUsersUsingGET_RAW_URL
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsersUsingGET_RAW_URL = function() {
  return '/user'
}
export const getUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createUserUsingPOST
 * url: createUserUsingPOSTURL
 * method: createUserUsingPOST_TYPE
 * raw_url: createUserUsingPOST_RAW_URL
 * @param body - 
 */
export const createUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: resendRegisterTokenUsingPOST
 * url: resendRegisterTokenUsingPOSTURL
 * method: resendRegisterTokenUsingPOST_TYPE
 * raw_url: resendRegisterTokenUsingPOST_RAW_URL
 * @param id - 
 */
export const resendRegisterTokenUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/resend-register-token'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const resendRegisterTokenUsingPOST_RAW_URL = function() {
  return '/user/{id}/resend-register-token'
}
export const resendRegisterTokenUsingPOST_TYPE = function() {
  return 'post'
}
export const resendRegisterTokenUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/resend-register-token'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTwoFactorAuthQRCodeUsingGET
 * url: getTwoFactorAuthQRCodeUsingGETURL
 * method: getTwoFactorAuthQRCodeUsingGET_TYPE
 * raw_url: getTwoFactorAuthQRCodeUsingGET_RAW_URL
 */
export const getTwoFactorAuthQRCodeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/two-factor-auth'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTwoFactorAuthQRCodeUsingGET_RAW_URL = function() {
  return '/user/two-factor-auth'
}
export const getTwoFactorAuthQRCodeUsingGET_TYPE = function() {
  return 'get'
}
export const getTwoFactorAuthQRCodeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/two-factor-auth'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: twoFactorAuthEnableUsingPOST
 * url: twoFactorAuthEnableUsingPOSTURL
 * method: twoFactorAuthEnableUsingPOST_TYPE
 * raw_url: twoFactorAuthEnableUsingPOST_RAW_URL
 * @param body - 
 */
export const twoFactorAuthEnableUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/two-factor-auth'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const twoFactorAuthEnableUsingPOST_RAW_URL = function() {
  return '/user/two-factor-auth'
}
export const twoFactorAuthEnableUsingPOST_TYPE = function() {
  return 'post'
}
export const twoFactorAuthEnableUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/two-factor-auth'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: twoFactorAuthDisableUsingDELETE
 * url: twoFactorAuthDisableUsingDELETEURL
 * method: twoFactorAuthDisableUsingDELETE_TYPE
 * raw_url: twoFactorAuthDisableUsingDELETE_RAW_URL
 */
export const twoFactorAuthDisableUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/two-factor-auth'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const twoFactorAuthDisableUsingDELETE_RAW_URL = function() {
  return '/user/two-factor-auth'
}
export const twoFactorAuthDisableUsingDELETE_TYPE = function() {
  return 'delete'
}
export const twoFactorAuthDisableUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/two-factor-auth'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getAllUserSettingsUsingGET
 * url: getAllUserSettingsUsingGETURL
 * method: getAllUserSettingsUsingGET_TYPE
 * raw_url: getAllUserSettingsUsingGET_RAW_URL
 */
export const getAllUserSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user-setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllUserSettingsUsingGET_RAW_URL = function() {
  return '/user-setting'
}
export const getAllUserSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllUserSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user-setting'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: saveUserSettingsUsingPOST
 * url: saveUserSettingsUsingPOSTURL
 * method: saveUserSettingsUsingPOST_TYPE
 * raw_url: saveUserSettingsUsingPOST_RAW_URL
 * @param body - 
 */
export const saveUserSettingsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user-setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveUserSettingsUsingPOST_RAW_URL = function() {
  return '/user-setting'
}
export const saveUserSettingsUsingPOST_TYPE = function() {
  return 'post'
}
export const saveUserSettingsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user-setting'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerRestsUsingGET
 * url: getTriggerRestsUsingGETURL
 * method: getTriggerRestsUsingGET_TYPE
 * raw_url: getTriggerRestsUsingGET_RAW_URL
 * @param url - 
 * @param method - 
 * @param isSystem - 
 * @param processId - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTriggerRestsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerRestsUsingGET_RAW_URL = function() {
  return '/trigger-rest'
}
export const getTriggerRestsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerRestsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest'
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTriggerRestUsingPOST
 * url: createTriggerRestUsingPOSTURL
 * method: createTriggerRestUsingPOST_TYPE
 * raw_url: createTriggerRestUsingPOST_RAW_URL
 * @param body - 
 */
export const createTriggerRestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerRestUsingPOST_RAW_URL = function() {
  return '/trigger-rest'
}
export const createTriggerRestUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerRestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerMessagingsUsingGET
 * url: getTriggerMessagingsUsingGETURL
 * method: getTriggerMessagingsUsingGET_TYPE
 * raw_url: getTriggerMessagingsUsingGET_RAW_URL
 * @param service - 
 * @param processId - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTriggerMessagingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['service'] !== undefined) {
    queryParameters['service'] = parameters['service']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerMessagingsUsingGET_RAW_URL = function() {
  return '/trigger-messaging'
}
export const getTriggerMessagingsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerMessagingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging'
  if (parameters['service'] !== undefined) {
    queryParameters['service'] = parameters['service']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTriggerMessagingUsingPOST
 * url: createTriggerMessagingUsingPOSTURL
 * method: createTriggerMessagingUsingPOST_TYPE
 * raw_url: createTriggerMessagingUsingPOST_RAW_URL
 * @param body - 
 */
export const createTriggerMessagingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerMessagingUsingPOST_RAW_URL = function() {
  return '/trigger-messaging'
}
export const createTriggerMessagingUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerMessagingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerEventHandlersUsingGET
 * url: getTriggerEventHandlersUsingGETURL
 * method: getTriggerEventHandlersUsingGET_TYPE
 * raw_url: getTriggerEventHandlersUsingGET_RAW_URL
 * @param listenType - 
 * @param listenStatus - 
 * @param listenProcessId - 
 * @param listenAllProcesses - 
 * @param processId - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTriggerEventHandlersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['listenType'] !== undefined) {
    queryParameters['listenType'] = parameters['listenType']
  }
  if (parameters['listenStatus'] !== undefined) {
    queryParameters['listenStatus'] = parameters['listenStatus']
  }
  if (parameters['listenProcessId'] !== undefined) {
    queryParameters['listenProcessId'] = parameters['listenProcessId']
  }
  if (parameters['listenAllProcesses'] !== undefined) {
    queryParameters['listenAllProcesses'] = parameters['listenAllProcesses']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerEventHandlersUsingGET_RAW_URL = function() {
  return '/trigger-event-handler'
}
export const getTriggerEventHandlersUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerEventHandlersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler'
  if (parameters['listenType'] !== undefined) {
    queryParameters['listenType'] = parameters['listenType']
  }
  if (parameters['listenStatus'] !== undefined) {
    queryParameters['listenStatus'] = parameters['listenStatus']
  }
  if (parameters['listenProcessId'] !== undefined) {
    queryParameters['listenProcessId'] = parameters['listenProcessId']
  }
  if (parameters['listenAllProcesses'] !== undefined) {
    queryParameters['listenAllProcesses'] = parameters['listenAllProcesses']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTriggerEventHandlerUsingPOST
 * url: createTriggerEventHandlerUsingPOSTURL
 * method: createTriggerEventHandlerUsingPOST_TYPE
 * raw_url: createTriggerEventHandlerUsingPOST_RAW_URL
 * @param body - 
 */
export const createTriggerEventHandlerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerEventHandlerUsingPOST_RAW_URL = function() {
  return '/trigger-event-handler'
}
export const createTriggerEventHandlerUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerEventHandlerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerCronsUsingGET
 * url: getTriggerCronsUsingGETURL
 * method: getTriggerCronsUsingGET_TYPE
 * raw_url: getTriggerCronsUsingGET_RAW_URL
 * @param processId - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTriggerCronsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerCronsUsingGET_RAW_URL = function() {
  return '/trigger-cron'
}
export const getTriggerCronsUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerCronsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron'
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTriggerCronUsingPOST
 * url: createTriggerCronUsingPOSTURL
 * method: createTriggerCronUsingPOST_TYPE
 * raw_url: createTriggerCronUsingPOST_RAW_URL
 * @param body - 
 */
export const createTriggerCronUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTriggerCronUsingPOST_RAW_URL = function() {
  return '/trigger-cron'
}
export const createTriggerCronUsingPOST_TYPE = function() {
  return 'post'
}
export const createTriggerCronUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTranslationsUsingGET
 * url: getTranslationsUsingGETURL
 * method: getTranslationsUsingGET_TYPE
 * raw_url: getTranslationsUsingGET_RAW_URL
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTranslationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTranslationsUsingGET_RAW_URL = function() {
  return '/translation'
}
export const getTranslationsUsingGET_TYPE = function() {
  return 'get'
}
export const getTranslationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTranslationUsingPOST
 * url: createTranslationUsingPOSTURL
 * method: createTranslationUsingPOST_TYPE
 * raw_url: createTranslationUsingPOST_RAW_URL
 * @param body - 
 */
export const createTranslationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTranslationUsingPOST_RAW_URL = function() {
  return '/translation'
}
export const createTranslationUsingPOST_TYPE = function() {
  return 'post'
}
export const createTranslationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTextTemplatesUsingGET
 * url: getTextTemplatesUsingGETURL
 * method: getTextTemplatesUsingGET_TYPE
 * raw_url: getTextTemplatesUsingGET_RAW_URL
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTextTemplatesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplatesUsingGET_RAW_URL = function() {
  return '/text-template'
}
export const getTextTemplatesUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplatesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTextTemplateUsingPOST
 * url: createTextTemplateUsingPOSTURL
 * method: createTextTemplateUsingPOST_TYPE
 * raw_url: createTextTemplateUsingPOST_RAW_URL
 * @param body - 
 */
export const createTextTemplateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTextTemplateUsingPOST_RAW_URL = function() {
  return '/text-template'
}
export const createTextTemplateUsingPOST_TYPE = function() {
  return 'post'
}
export const createTextTemplateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTextTemplateTranslationsUsingGET
 * url: getTextTemplateTranslationsUsingGETURL
 * method: getTextTemplateTranslationsUsingGET_TYPE
 * raw_url: getTextTemplateTranslationsUsingGET_RAW_URL
 * @param templateId - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getTextTemplateTranslationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}/translation'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTextTemplateTranslationsUsingGET_RAW_URL = function() {
  return '/text-template/{templateId}/translation'
}
export const getTextTemplateTranslationsUsingGET_TYPE = function() {
  return 'get'
}
export const getTextTemplateTranslationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}/translation'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTextTemplateTranslationUsingPOST
 * url: createTextTemplateTranslationUsingPOSTURL
 * method: createTextTemplateTranslationUsingPOST_TYPE
 * raw_url: createTextTemplateTranslationUsingPOST_RAW_URL
 * @param templateId - 
 * @param body - 
 */
export const createTextTemplateTranslationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/{templateId}/translation'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters['templateId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: templateId'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTextTemplateTranslationUsingPOST_RAW_URL = function() {
  return '/text-template/{templateId}/translation'
}
export const createTextTemplateTranslationUsingPOST_TYPE = function() {
  return 'post'
}
export const createTextTemplateTranslationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/{templateId}/translation'
  path = path.replace('{templateId}', `${parameters['templateId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPdfUsingPOST
 * url: createPdfUsingPOSTURL
 * method: createPdfUsingPOST_TYPE
 * raw_url: createPdfUsingPOST_RAW_URL
 * @param body - 
 */
export const createPdfUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/text-template/pdf'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPdfUsingPOST_RAW_URL = function() {
  return '/text-template/pdf'
}
export const createPdfUsingPOST_TYPE = function() {
  return 'post'
}
export const createPdfUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/text-template/pdf'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getStoragesUsingGET
 * url: getStoragesUsingGETURL
 * method: getStoragesUsingGET_TYPE
 * raw_url: getStoragesUsingGET_RAW_URL
 * @param processId - 
 * @param key - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getStoragesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storage'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['key'] !== undefined) {
    queryParameters['key'] = parameters['key']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoragesUsingGET_RAW_URL = function() {
  return '/storage'
}
export const getStoragesUsingGET_TYPE = function() {
  return 'get'
}
export const getStoragesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storage'
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['key'] !== undefined) {
    queryParameters['key'] = parameters['key']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createStorageUsingPOST
 * url: createStorageUsingPOSTURL
 * method: createStorageUsingPOST_TYPE
 * raw_url: createStorageUsingPOST_RAW_URL
 * @param body - 
 */
export const createStorageUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storage'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createStorageUsingPOST_RAW_URL = function() {
  return '/storage'
}
export const createStorageUsingPOST_TYPE = function() {
  return 'post'
}
export const createStorageUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storage'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createProcessSettingUsingPOST
 * url: createProcessSettingUsingPOSTURL
 * method: createProcessSettingUsingPOST_TYPE
 * raw_url: createProcessSettingUsingPOST_RAW_URL
 * @param body - 
 */
export const createProcessSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createProcessSettingUsingPOST_RAW_URL = function() {
  return '/setting/process'
}
export const createProcessSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createProcessSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/process'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPluginSettingUsingPOST
 * url: createPluginSettingUsingPOSTURL
 * method: createPluginSettingUsingPOST_TYPE
 * raw_url: createPluginSettingUsingPOST_RAW_URL
 * @param body - 
 */
export const createPluginSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginSettingUsingPOST_RAW_URL = function() {
  return '/setting/plugin'
}
export const createPluginSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createInstanceProcessingSettingUsingPOST
 * url: createInstanceProcessingSettingUsingPOSTURL
 * method: createInstanceProcessingSettingUsingPOST_TYPE
 * raw_url: createInstanceProcessingSettingUsingPOST_RAW_URL
 * @param body - 
 */
export const createInstanceProcessingSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/instance-processing'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createInstanceProcessingSettingUsingPOST_RAW_URL = function() {
  return '/setting/instance-processing'
}
export const createInstanceProcessingSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createInstanceProcessingSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/instance-processing'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createInstanceAdminSettingUsingPOST
 * url: createInstanceAdminSettingUsingPOSTURL
 * method: createInstanceAdminSettingUsingPOST_TYPE
 * raw_url: createInstanceAdminSettingUsingPOST_RAW_URL
 * @param body - 
 */
export const createInstanceAdminSettingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/instance-admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createInstanceAdminSettingUsingPOST_RAW_URL = function() {
  return '/setting/instance-admin'
}
export const createInstanceAdminSettingUsingPOST_TYPE = function() {
  return 'post'
}
export const createInstanceAdminSettingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/instance-admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: setUserPasswordUsingPOST
 * url: setUserPasswordUsingPOSTURL
 * method: setUserPasswordUsingPOST_TYPE
 * raw_url: setUserPasswordUsingPOST_RAW_URL
 * @param body - 
 */
export const setUserPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/set-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setUserPasswordUsingPOST_RAW_URL = function() {
  return '/set-password'
}
export const setUserPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const setUserPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/set-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getRolesUsingGET
 * url: getRolesUsingGETURL
 * method: getRolesUsingGET_TYPE
 * raw_url: getRolesUsingGET_RAW_URL
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRolesUsingGET_RAW_URL = function() {
  return '/role'
}
export const getRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createRoleUsingPOST
 * url: createRoleUsingPOSTURL
 * method: createRoleUsingPOST_TYPE
 * raw_url: createRoleUsingPOST_RAW_URL
 * @param body - 
 */
export const createRoleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRoleUsingPOST_RAW_URL = function() {
  return '/role'
}
export const createRoleUsingPOST_TYPE = function() {
  return 'post'
}
export const createRoleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: regexTestUsingPOST
 * url: regexTestUsingPOSTURL
 * method: regexTestUsingPOST_TYPE
 * raw_url: regexTestUsingPOST_RAW_URL
 * @param body - 
 */
export const regexTestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/regex/test'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const regexTestUsingPOST_RAW_URL = function() {
  return '/regex/test'
}
export const regexTestUsingPOST_TYPE = function() {
  return 'post'
}
export const regexTestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/regex/test'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessesUsingGET
 * url: getProcessesUsingGETURL
 * method: getProcessesUsingGET_TYPE
 * raw_url: getProcessesUsingGET_RAW_URL
 * @param isSystem - 
 * @param permissionType - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getProcessesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessesUsingGET_RAW_URL = function() {
  return '/process'
}
export const getProcessesUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process'
  if (parameters['isSystem'] !== undefined) {
    queryParameters['isSystem'] = parameters['isSystem']
  }
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createProcessUsingPOST
 * url: createProcessUsingPOSTURL
 * method: createProcessUsingPOST_TYPE
 * raw_url: createProcessUsingPOST_RAW_URL
 * @param body - 
 */
export const createProcessUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createProcessUsingPOST_RAW_URL = function() {
  return '/process'
}
export const createProcessUsingPOST_TYPE = function() {
  return 'post'
}
export const createProcessUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createTwilioProcessCredentialUsingPOST
 * url: createTwilioProcessCredentialUsingPOSTURL
 * method: createTwilioProcessCredentialUsingPOST_TYPE
 * raw_url: createTwilioProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createTwilioProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/twilio'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createTwilioProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/twilio'
}
export const createTwilioProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createTwilioProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/twilio'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testTwilioProcessCredentialUsingPOST
 * url: testTwilioProcessCredentialUsingPOSTURL
 * method: testTwilioProcessCredentialUsingPOST_TYPE
 * raw_url: testTwilioProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testTwilioProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/twilio'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testTwilioProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/twilio'
}
export const testTwilioProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testTwilioProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/twilio'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testSshCredentialUsingPOST
 * url: testSshCredentialUsingPOSTURL
 * method: testSshCredentialUsingPOST_TYPE
 * raw_url: testSshCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testSshCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/ssh'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSshCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/ssh'
}
export const testSshCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSshCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/ssh'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testSshKeyCredentialUsingPOST
 * url: testSshKeyCredentialUsingPOSTURL
 * method: testSshKeyCredentialUsingPOST_TYPE
 * raw_url: testSshKeyCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testSshKeyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/ssh-key'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSshKeyCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/ssh-key'
}
export const testSshKeyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSshKeyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/ssh-key'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testSmtpProcessCredentialUsingPOST
 * url: testSmtpProcessCredentialUsingPOSTURL
 * method: testSmtpProcessCredentialUsingPOST_TYPE
 * raw_url: testSmtpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testSmtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/smtp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSmtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/smtp'
}
export const testSmtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSmtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/smtp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testSlackProcessCredentialUsingPOST
 * url: testSlackProcessCredentialUsingPOSTURL
 * method: testSlackProcessCredentialUsingPOST_TYPE
 * raw_url: testSlackProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testSlackProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/slack'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSlackProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/slack'
}
export const testSlackProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSlackProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/slack'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testSftpProcessCredentialUsingPOST
 * url: testSftpProcessCredentialUsingPOSTURL
 * method: testSftpProcessCredentialUsingPOST_TYPE
 * raw_url: testSftpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testSftpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/sftp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testSftpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/sftp'
}
export const testSftpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testSftpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/sftp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testRabbitmqCredentialUsingPOST
 * url: testRabbitmqCredentialUsingPOSTURL
 * method: testRabbitmqCredentialUsingPOST_TYPE
 * raw_url: testRabbitmqCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testRabbitmqCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/rabbitmq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testRabbitmqCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/rabbitmq'
}
export const testRabbitmqCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testRabbitmqCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/rabbitmq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testPaymentSensePacCredentialUsingPOST
 * url: testPaymentSensePacCredentialUsingPOSTURL
 * method: testPaymentSensePacCredentialUsingPOST_TYPE
 * raw_url: testPaymentSensePacCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testPaymentSensePacCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/payment-sense-pac'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testPaymentSensePacCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/payment-sense-pac'
}
export const testPaymentSensePacCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testPaymentSensePacCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/payment-sense-pac'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testPaymentSenseConnectECredentialUsingPOST
 * url: testPaymentSenseConnectECredentialUsingPOSTURL
 * method: testPaymentSenseConnectECredentialUsingPOST_TYPE
 * raw_url: testPaymentSenseConnectECredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testPaymentSenseConnectECredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/payment-sense-connect-e'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testPaymentSenseConnectECredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/payment-sense-connect-e'
}
export const testPaymentSenseConnectECredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testPaymentSenseConnectECredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/payment-sense-connect-e'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testOutlookCalendarCredentialUsingPOST
 * url: testOutlookCalendarCredentialUsingPOSTURL
 * method: testOutlookCalendarCredentialUsingPOST_TYPE
 * raw_url: testOutlookCalendarCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testOutlookCalendarCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/outlook-calendar'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testOutlookCalendarCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/outlook-calendar'
}
export const testOutlookCalendarCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testOutlookCalendarCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/outlook-calendar'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testOpenAICredentialUsingPOST
 * url: testOpenAICredentialUsingPOSTURL
 * method: testOpenAICredentialUsingPOST_TYPE
 * raw_url: testOpenAICredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testOpenAICredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/open-ai'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testOpenAICredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/open-ai'
}
export const testOpenAICredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testOpenAICredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/open-ai'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testMqttCredentialUsingPOST
 * url: testMqttCredentialUsingPOSTURL
 * method: testMqttCredentialUsingPOST_TYPE
 * raw_url: testMqttCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testMqttCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/mqtt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testMqttCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/mqtt'
}
export const testMqttCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testMqttCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/mqtt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testMongodbProcessCredentialUsingPOST
 * url: testMongodbProcessCredentialUsingPOSTURL
 * method: testMongodbProcessCredentialUsingPOST_TYPE
 * raw_url: testMongodbProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testMongodbProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/mongodb'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testMongodbProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/mongodb'
}
export const testMongodbProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testMongodbProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/mongodb'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testLdapCredentialUsingPOST
 * url: testLdapCredentialUsingPOSTURL
 * method: testLdapCredentialUsingPOST_TYPE
 * raw_url: testLdapCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testLdapCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/ldap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testLdapCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/ldap'
}
export const testLdapCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testLdapCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/ldap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testKafkaCredentialUsingPOST
 * url: testKafkaCredentialUsingPOSTURL
 * method: testKafkaCredentialUsingPOST_TYPE
 * raw_url: testKafkaCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testKafkaCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/kafka'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testKafkaCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/kafka'
}
export const testKafkaCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testKafkaCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/kafka'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testJwtProcessCredentialUsingPOST
 * url: testJwtProcessCredentialUsingPOSTURL
 * method: testJwtProcessCredentialUsingPOST_TYPE
 * raw_url: testJwtProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testJwtProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/jwt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJwtProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/jwt'
}
export const testJwtProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJwtProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/jwt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testJmsCredentialUsingPOST
 * url: testJmsCredentialUsingPOSTURL
 * method: testJmsCredentialUsingPOST_TYPE
 * raw_url: testJmsCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testJmsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/jms'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJmsCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/jms'
}
export const testJmsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJmsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/jms'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testJdbcProcessCredentialUsingPOST
 * url: testJdbcProcessCredentialUsingPOSTURL
 * method: testJdbcProcessCredentialUsingPOST_TYPE
 * raw_url: testJdbcProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testJdbcProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/jdbc'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testJdbcProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/jdbc'
}
export const testJdbcProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testJdbcProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/jdbc'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testImapProcessCredentialUsingPOST
 * url: testImapProcessCredentialUsingPOSTURL
 * method: testImapProcessCredentialUsingPOST_TYPE
 * raw_url: testImapProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testImapProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/imap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testImapProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/imap'
}
export const testImapProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testImapProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/imap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testFtpProcessCredentialUsingPOST
 * url: testFtpProcessCredentialUsingPOSTURL
 * method: testFtpProcessCredentialUsingPOST_TYPE
 * raw_url: testFtpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testFtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/ftp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testFtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/ftp'
}
export const testFtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testFtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/ftp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testFlowyCredentialUsingPOST
 * url: testFlowyCredentialUsingPOSTURL
 * method: testFlowyCredentialUsingPOST_TYPE
 * raw_url: testFlowyCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testFlowyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/flowy'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testFlowyCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/flowy'
}
export const testFlowyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testFlowyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/flowy'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: testAwsCredentialUsingPOST
 * url: testAwsCredentialUsingPOSTURL
 * method: testAwsCredentialUsingPOST_TYPE
 * raw_url: testAwsCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const testAwsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/test/aws'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const testAwsCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/test/aws'
}
export const testAwsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const testAwsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/test/aws'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSshCredentialUsingPOST
 * url: createSshCredentialUsingPOSTURL
 * method: createSshCredentialUsingPOST_TYPE
 * raw_url: createSshCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSshCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ssh'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSshCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/ssh'
}
export const createSshCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSshCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ssh'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSshKeyCredentialUsingPOST
 * url: createSshKeyCredentialUsingPOSTURL
 * method: createSshKeyCredentialUsingPOST_TYPE
 * raw_url: createSshKeyCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSshKeyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ssh-key'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSshKeyCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/ssh-key'
}
export const createSshKeyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSshKeyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ssh-key'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSmtpProcessCredentialUsingPOST
 * url: createSmtpProcessCredentialUsingPOSTURL
 * method: createSmtpProcessCredentialUsingPOST_TYPE
 * raw_url: createSmtpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSmtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/smtp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSmtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/smtp'
}
export const createSmtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSmtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/smtp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSlackProcessCredentialUsingPOST
 * url: createSlackProcessCredentialUsingPOSTURL
 * method: createSlackProcessCredentialUsingPOST_TYPE
 * raw_url: createSlackProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSlackProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/slack'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSlackProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/slack'
}
export const createSlackProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSlackProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/slack'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSftpProcessCredentialUsingPOST
 * url: createSftpProcessCredentialUsingPOSTURL
 * method: createSftpProcessCredentialUsingPOST_TYPE
 * raw_url: createSftpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSftpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/sftp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSftpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/sftp'
}
export const createSftpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSftpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/sftp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createSecurityCredentialUsingPOST
 * url: createSecurityCredentialUsingPOSTURL
 * method: createSecurityCredentialUsingPOST_TYPE
 * raw_url: createSecurityCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createSecurityCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/security'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createSecurityCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/security'
}
export const createSecurityCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createSecurityCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/security'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createScriptCredentialUsingPOST
 * url: createScriptCredentialUsingPOSTURL
 * method: createScriptCredentialUsingPOST_TYPE
 * raw_url: createScriptCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createScriptCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/script'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createScriptCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/script'
}
export const createScriptCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createScriptCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/script'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createRestCredentialUsingPOST
 * url: createRestCredentialUsingPOSTURL
 * method: createRestCredentialUsingPOST_TYPE
 * raw_url: createRestCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createRestCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRestCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/rest'
}
export const createRestCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRestCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createRestBearerCredentialUsingPOST
 * url: createRestBearerCredentialUsingPOSTURL
 * method: createRestBearerCredentialUsingPOST_TYPE
 * raw_url: createRestBearerCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createRestBearerCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest-bearer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRestBearerCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/rest-bearer'
}
export const createRestBearerCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRestBearerCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest-bearer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createRestBasicCredentialUsingPOST
 * url: createRestBasicCredentialUsingPOSTURL
 * method: createRestBasicCredentialUsingPOST_TYPE
 * raw_url: createRestBasicCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createRestBasicCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rest-basic'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRestBasicCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/rest-basic'
}
export const createRestBasicCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRestBasicCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rest-basic'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createRabbitmqCredentialUsingPOST
 * url: createRabbitmqCredentialUsingPOSTURL
 * method: createRabbitmqCredentialUsingPOST_TYPE
 * raw_url: createRabbitmqCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createRabbitmqCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/rabbitmq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createRabbitmqCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/rabbitmq'
}
export const createRabbitmqCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createRabbitmqCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/rabbitmq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPluginCredentialUsingPOST
 * url: createPluginCredentialUsingPOSTURL
 * method: createPluginCredentialUsingPOST_TYPE
 * raw_url: createPluginCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createPluginCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/plugin'
}
export const createPluginCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPaymentSensePacCredentialUsingPOST
 * url: createPaymentSensePacCredentialUsingPOSTURL
 * method: createPaymentSensePacCredentialUsingPOST_TYPE
 * raw_url: createPaymentSensePacCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createPaymentSensePacCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/payment-sense-pac'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPaymentSensePacCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/payment-sense-pac'
}
export const createPaymentSensePacCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPaymentSensePacCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/payment-sense-pac'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPaymentSenseConnectECredentialUsingPOST
 * url: createPaymentSenseConnectECredentialUsingPOSTURL
 * method: createPaymentSenseConnectECredentialUsingPOST_TYPE
 * raw_url: createPaymentSenseConnectECredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createPaymentSenseConnectECredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/payment-sense-connect-e'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPaymentSenseConnectECredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/payment-sense-connect-e'
}
export const createPaymentSenseConnectECredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createPaymentSenseConnectECredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/payment-sense-connect-e'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createOutlookCalendarCredentialUsingPOST
 * url: createOutlookCalendarCredentialUsingPOSTURL
 * method: createOutlookCalendarCredentialUsingPOST_TYPE
 * raw_url: createOutlookCalendarCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createOutlookCalendarCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/outlook-calendar'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOutlookCalendarCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/outlook-calendar'
}
export const createOutlookCalendarCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createOutlookCalendarCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/outlook-calendar'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createOpenAIProcessCredentialUsingPOST
 * url: createOpenAIProcessCredentialUsingPOSTURL
 * method: createOpenAIProcessCredentialUsingPOST_TYPE
 * raw_url: createOpenAIProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createOpenAIProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/open-ai'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOpenAIProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/open-ai'
}
export const createOpenAIProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createOpenAIProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/open-ai'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createMqttCredentialUsingPOST
 * url: createMqttCredentialUsingPOSTURL
 * method: createMqttCredentialUsingPOST_TYPE
 * raw_url: createMqttCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createMqttCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/mqtt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createMqttCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/mqtt'
}
export const createMqttCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createMqttCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/mqtt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createMongodbProcessCredentialUsingPOST
 * url: createMongodbProcessCredentialUsingPOSTURL
 * method: createMongodbProcessCredentialUsingPOST_TYPE
 * raw_url: createMongodbProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createMongodbProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/mongodb'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createMongodbProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/mongodb'
}
export const createMongodbProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createMongodbProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/mongodb'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createLdapCredentialUsingPOST
 * url: createLdapCredentialUsingPOSTURL
 * method: createLdapCredentialUsingPOST_TYPE
 * raw_url: createLdapCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createLdapCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ldap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createLdapCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/ldap'
}
export const createLdapCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createLdapCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ldap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createKafkaCredentialUsingPOST
 * url: createKafkaCredentialUsingPOSTURL
 * method: createKafkaCredentialUsingPOST_TYPE
 * raw_url: createKafkaCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createKafkaCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/kafka'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createKafkaCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/kafka'
}
export const createKafkaCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createKafkaCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/kafka'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createJwtProcessCredentialUsingPOST
 * url: createJwtProcessCredentialUsingPOSTURL
 * method: createJwtProcessCredentialUsingPOST_TYPE
 * raw_url: createJwtProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createJwtProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jwt'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJwtProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/jwt'
}
export const createJwtProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJwtProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jwt'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createJmsCredentialUsingPOST
 * url: createJmsCredentialUsingPOSTURL
 * method: createJmsCredentialUsingPOST_TYPE
 * raw_url: createJmsCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createJmsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jms'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJmsCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/jms'
}
export const createJmsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJmsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jms'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createJdbcProcessCredentialUsingPOST
 * url: createJdbcProcessCredentialUsingPOSTURL
 * method: createJdbcProcessCredentialUsingPOST_TYPE
 * raw_url: createJdbcProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createJdbcProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jdbc'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createJdbcProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/jdbc'
}
export const createJdbcProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createJdbcProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jdbc'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: executeJdbcScriptUsingPOST
 * url: executeJdbcScriptUsingPOSTURL
 * method: executeJdbcScriptUsingPOST_TYPE
 * raw_url: executeJdbcScriptUsingPOST_RAW_URL
 * @param id - 
 * @param body - 
 */
export const executeJdbcScriptUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/jdbc/{id}/execute-script'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const executeJdbcScriptUsingPOST_RAW_URL = function() {
  return '/process-credential/jdbc/{id}/execute-script'
}
export const executeJdbcScriptUsingPOST_TYPE = function() {
  return 'post'
}
export const executeJdbcScriptUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/jdbc/{id}/execute-script'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createImapProcessCredentialUsingPOST
 * url: createImapProcessCredentialUsingPOSTURL
 * method: createImapProcessCredentialUsingPOST_TYPE
 * raw_url: createImapProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createImapProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/imap'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createImapProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/imap'
}
export const createImapProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createImapProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/imap'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createFtpProcessCredentialUsingPOST
 * url: createFtpProcessCredentialUsingPOSTURL
 * method: createFtpProcessCredentialUsingPOST_TYPE
 * raw_url: createFtpProcessCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createFtpProcessCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/ftp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createFtpProcessCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/ftp'
}
export const createFtpProcessCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createFtpProcessCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/ftp'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createFlowyCredentialUsingPOST
 * url: createFlowyCredentialUsingPOSTURL
 * method: createFlowyCredentialUsingPOST_TYPE
 * raw_url: createFlowyCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createFlowyCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/flowy'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createFlowyCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/flowy'
}
export const createFlowyCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createFlowyCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/flowy'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createAwsCredentialUsingPOST
 * url: createAwsCredentialUsingPOSTURL
 * method: createAwsCredentialUsingPOST_TYPE
 * raw_url: createAwsCredentialUsingPOST_RAW_URL
 * @param body - 
 */
export const createAwsCredentialUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/aws'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createAwsCredentialUsingPOST_RAW_URL = function() {
  return '/process-credential/aws'
}
export const createAwsCredentialUsingPOST_TYPE = function() {
  return 'post'
}
export const createAwsCredentialUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/aws'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getPluginsUsingGET
 * url: getPluginsUsingGETURL
 * method: getPluginsUsingGET_TYPE
 * raw_url: getPluginsUsingGET_RAW_URL
 * @param permissionType - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getPluginsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPluginsUsingGET_RAW_URL = function() {
  return '/plugin'
}
export const getPluginsUsingGET_TYPE = function() {
  return 'get'
}
export const getPluginsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin'
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createPluginUsingPOST
 * url: createPluginUsingPOSTURL
 * method: createPluginUsingPOST_TYPE
 * raw_url: createPluginUsingPOST_RAW_URL
 * @param body - 
 */
export const createPluginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPluginUsingPOST_RAW_URL = function() {
  return '/plugin'
}
export const createPluginUsingPOST_TYPE = function() {
  return 'post'
}
export const createPluginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: uploadPluginJarUsingPOST
 * url: uploadPluginJarUsingPOSTURL
 * method: uploadPluginJarUsingPOST_TYPE
 * raw_url: uploadPluginJarUsingPOST_RAW_URL
 * @param pluginId - 
 * @param file - 
 */
export const uploadPluginJarUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{pluginId}/jar'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pluginId}', `${parameters['pluginId']}`)
  if (parameters['pluginId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pluginId'))
  }
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadPluginJarUsingPOST_RAW_URL = function() {
  return '/plugin/{pluginId}/jar'
}
export const uploadPluginJarUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadPluginJarUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{pluginId}/jar'
  path = path.replace('{pluginId}', `${parameters['pluginId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getModulesUsingGET
 * url: getModulesUsingGETURL
 * method: getModulesUsingGET_TYPE
 * raw_url: getModulesUsingGET_RAW_URL
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getModulesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getModulesUsingGET_RAW_URL = function() {
  return '/module'
}
export const getModulesUsingGET_TYPE = function() {
  return 'get'
}
export const getModulesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createModuleUsingPOST
 * url: createModuleUsingPOSTURL
 * method: createModuleUsingPOST_TYPE
 * raw_url: createModuleUsingPOST_RAW_URL
 * @param body - 
 */
export const createModuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createModuleUsingPOST_RAW_URL = function() {
  return '/module'
}
export const createModuleUsingPOST_TYPE = function() {
  return 'post'
}
export const createModuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: validateUsingPOST
 * url: validateUsingPOSTURL
 * method: validateUsingPOST_TYPE
 * raw_url: validateUsingPOST_RAW_URL
 * @param file - JSON file with resources to import. Please follow ModuleImport model
 */
export const validateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/validate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const validateUsingPOST_RAW_URL = function() {
  return '/module/validate'
}
export const validateUsingPOST_TYPE = function() {
  return 'post'
}
export const validateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/validate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: importModuleUsingPOST
 * url: importModuleUsingPOSTURL
 * method: importModuleUsingPOST_TYPE
 * raw_url: importModuleUsingPOST_RAW_URL
 * @param file - JSON file with resources to import. Please follow ModuleImport model
 */
export const importModuleUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importModuleUsingPOST_RAW_URL = function() {
  return '/module/import'
}
export const importModuleUsingPOST_TYPE = function() {
  return 'post'
}
export const importModuleUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param body - 
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getLibrariesUsingGET
 * url: getLibrariesUsingGETURL
 * method: getLibrariesUsingGET_TYPE
 * raw_url: getLibrariesUsingGET_RAW_URL
 * @param permissionType - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getLibrariesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLibrariesUsingGET_RAW_URL = function() {
  return '/library'
}
export const getLibrariesUsingGET_TYPE = function() {
  return 'get'
}
export const getLibrariesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library'
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createLibraryUsingPOST
 * url: createLibraryUsingPOSTURL
 * method: createLibraryUsingPOST_TYPE
 * raw_url: createLibraryUsingPOST_RAW_URL
 * @param body - 
 */
export const createLibraryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createLibraryUsingPOST_RAW_URL = function() {
  return '/library'
}
export const createLibraryUsingPOST_TYPE = function() {
  return 'post'
}
export const createLibraryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: uploadLibraryUsingPOST
 * url: uploadLibraryUsingPOSTURL
 * method: uploadLibraryUsingPOST_TYPE
 * raw_url: uploadLibraryUsingPOST_RAW_URL
 * @param id - 
 * @param file - 
 */
export const uploadLibraryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}/upload'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadLibraryUsingPOST_RAW_URL = function() {
  return '/library/{id}/upload'
}
export const uploadLibraryUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadLibraryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}/upload'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubReviewsSearchUsingGET
 * url: hubReviewsSearchUsingGETURL
 * method: hubReviewsSearchUsingGET_TYPE
 * raw_url: hubReviewsSearchUsingGET_RAW_URL
 * @param credentialId - 
 * @param remoteModuleId - 
 * @param size - 
 * @param page - 
 */
export const hubReviewsSearchUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/review'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters['remoteModuleId'] !== undefined) {
    queryParameters['remoteModuleId'] = parameters['remoteModuleId']
  }
  if (parameters['remoteModuleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remoteModuleId'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubReviewsSearchUsingGET_RAW_URL = function() {
  return '/hub/review'
}
export const hubReviewsSearchUsingGET_TYPE = function() {
  return 'get'
}
export const hubReviewsSearchUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/review'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['remoteModuleId'] !== undefined) {
    queryParameters['remoteModuleId'] = parameters['remoteModuleId']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubReviewCreateUsingPOST
 * url: hubReviewCreateUsingPOSTURL
 * method: hubReviewCreateUsingPOST_TYPE
 * raw_url: hubReviewCreateUsingPOST_RAW_URL
 * @param body - 
 */
export const hubReviewCreateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/review'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const hubReviewCreateUsingPOST_RAW_URL = function() {
  return '/hub/review'
}
export const hubReviewCreateUsingPOST_TYPE = function() {
  return 'post'
}
export const hubReviewCreateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/review'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: forgotPasswordUsingPOST
 * url: forgotPasswordUsingPOSTURL
 * method: forgotPasswordUsingPOST_TYPE
 * raw_url: forgotPasswordUsingPOST_RAW_URL
 * @param body - 
 */
export const forgotPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/forgot-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const forgotPasswordUsingPOST_RAW_URL = function() {
  return '/forgot-password'
}
export const forgotPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const forgotPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/forgot-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: interruptEventsByProcessIdUsingPOST
 * url: interruptEventsByProcessIdUsingPOSTURL
 * method: interruptEventsByProcessIdUsingPOST_TYPE
 * raw_url: interruptEventsByProcessIdUsingPOST_RAW_URL
 * @param processId - 
 */
export const interruptEventsByProcessIdUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/interrupt/{processId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters['processId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: processId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const interruptEventsByProcessIdUsingPOST_RAW_URL = function() {
  return '/event/interrupt/{processId}'
}
export const interruptEventsByProcessIdUsingPOST_TYPE = function() {
  return 'post'
}
export const interruptEventsByProcessIdUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/interrupt/{processId}'
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEntitiesUsingGET
 * url: getEntitiesUsingGETURL
 * method: getEntitiesUsingGET_TYPE
 * raw_url: getEntitiesUsingGET_RAW_URL
 * @param permissionType - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getEntitiesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEntitiesUsingGET_RAW_URL = function() {
  return '/entity'
}
export const getEntitiesUsingGET_TYPE = function() {
  return 'get'
}
export const getEntitiesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity'
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: createEntityUsingPOST
 * url: createEntityUsingPOSTURL
 * method: createEntityUsingPOST_TYPE
 * raw_url: createEntityUsingPOST_RAW_URL
 * @param body - 
 */
export const createEntityUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createEntityUsingPOST_RAW_URL = function() {
  return '/entity'
}
export const createEntityUsingPOST_TYPE = function() {
  return 'post'
}
export const createEntityUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityResourcesValidateUsingPOST
 * url: entityResourcesValidateUsingPOSTURL
 * method: entityResourcesValidateUsingPOST_TYPE
 * raw_url: entityResourcesValidateUsingPOST_RAW_URL
 * @param id - 
 * @param body - 
 */
export const entityResourcesValidateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/resources/validate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const entityResourcesValidateUsingPOST_RAW_URL = function() {
  return '/entity/{id}/resources/validate'
}
export const entityResourcesValidateUsingPOST_TYPE = function() {
  return 'post'
}
export const entityResourcesValidateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/resources/validate'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityResourcesGenerateUsingPOST
 * url: entityResourcesGenerateUsingPOSTURL
 * method: entityResourcesGenerateUsingPOST_TYPE
 * raw_url: entityResourcesGenerateUsingPOST_RAW_URL
 * @param id - 
 * @param body - 
 */
export const entityResourcesGenerateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/resources/generate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const entityResourcesGenerateUsingPOST_RAW_URL = function() {
  return '/entity/{id}/resources/generate'
}
export const entityResourcesGenerateUsingPOST_TYPE = function() {
  return 'post'
}
export const entityResourcesGenerateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/resources/generate'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityPersistenceGenerateUsingPOST
 * url: entityPersistenceGenerateUsingPOSTURL
 * method: entityPersistenceGenerateUsingPOST_TYPE
 * raw_url: entityPersistenceGenerateUsingPOST_RAW_URL
 * @param id - 
 */
export const entityPersistenceGenerateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/persistence/generate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const entityPersistenceGenerateUsingPOST_RAW_URL = function() {
  return '/entity/{id}/persistence/generate'
}
export const entityPersistenceGenerateUsingPOST_TYPE = function() {
  return 'post'
}
export const entityPersistenceGenerateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/persistence/generate'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: generateEntityFromPersistenceUsingPOST
 * url: generateEntityFromPersistenceUsingPOSTURL
 * method: generateEntityFromPersistenceUsingPOST_TYPE
 * raw_url: generateEntityFromPersistenceUsingPOST_RAW_URL
 * @param body - 
 */
export const generateEntityFromPersistenceUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/from-persistence/generate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters['body'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: body'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const generateEntityFromPersistenceUsingPOST_RAW_URL = function() {
  return '/entity/from-persistence/generate'
}
export const generateEntityFromPersistenceUsingPOST_TYPE = function() {
  return 'post'
}
export const generateEntityFromPersistenceUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/from-persistence/generate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUserNameUsingGET
 * url: getUserNameUsingGETURL
 * method: getUserNameUsingGET_TYPE
 * raw_url: getUserNameUsingGET_RAW_URL
 * @param id - 
 */
export const getUserNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/name'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserNameUsingGET_RAW_URL = function() {
  return '/user/{id}/name'
}
export const getUserNameUsingGET_TYPE = function() {
  return 'get'
}
export const getUserNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/name'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableUserUsingGET
 * url: enableUserUsingGETURL
 * method: enableUserUsingGET_TYPE
 * raw_url: enableUserUsingGET_RAW_URL
 * @param id - 
 */
export const enableUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableUserUsingGET_RAW_URL = function() {
  return '/user/{id}/enable'
}
export const enableUserUsingGET_TYPE = function() {
  return 'get'
}
export const enableUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableUserUsingGET
 * url: disableUserUsingGETURL
 * method: disableUserUsingGET_TYPE
 * raw_url: disableUserUsingGET_RAW_URL
 * @param id - 
 */
export const disableUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableUserUsingGET_RAW_URL = function() {
  return '/user/{id}/disable'
}
export const disableUserUsingGET_TYPE = function() {
  return 'get'
}
export const disableUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getCurrentUserInfoUsingGET
 * url: getCurrentUserInfoUsingGETURL
 * method: getCurrentUserInfoUsingGET_TYPE
 * raw_url: getCurrentUserInfoUsingGET_RAW_URL
 */
export const getCurrentUserInfoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/me'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCurrentUserInfoUsingGET_RAW_URL = function() {
  return '/user/me'
}
export const getCurrentUserInfoUsingGET_TYPE = function() {
  return 'get'
}
export const getCurrentUserInfoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/me'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getUserSettingUsingGET
 * url: getUserSettingUsingGETURL
 * method: getUserSettingUsingGET_TYPE
 * raw_url: getUserSettingUsingGET_RAW_URL
 * @param key - 
 */
export const getUserSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user-setting/{key}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters['key'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: key'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserSettingUsingGET_RAW_URL = function() {
  return '/user-setting/{key}'
}
export const getUserSettingUsingGET_TYPE = function() {
  return 'get'
}
export const getUserSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user-setting/{key}'
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableTriggerRestUsingGET
 * url: enableTriggerRestUsingGETURL
 * method: enableTriggerRestUsingGET_TYPE
 * raw_url: enableTriggerRestUsingGET_RAW_URL
 * @param id - 
 */
export const enableTriggerRestUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerRestUsingGET_RAW_URL = function() {
  return '/trigger-rest/{id}/enable'
}
export const enableTriggerRestUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerRestUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableTriggerRestUsingGET
 * url: disableTriggerRestUsingGETURL
 * method: disableTriggerRestUsingGET_TYPE
 * raw_url: disableTriggerRestUsingGET_RAW_URL
 * @param id - 
 */
export const disableTriggerRestUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerRestUsingGET_RAW_URL = function() {
  return '/trigger-rest/{id}/disable'
}
export const disableTriggerRestUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerRestUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: triggerRestCheckUsingGET
 * url: triggerRestCheckUsingGETURL
 * method: triggerRestCheckUsingGET_TYPE
 * raw_url: triggerRestCheckUsingGET_RAW_URL
 * @param method - 
 * @param url - 
 */
export const triggerRestCheckUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-rest/check'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['method'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: method'))
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters['url'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: url'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const triggerRestCheckUsingGET_RAW_URL = function() {
  return '/trigger-rest/check'
}
export const triggerRestCheckUsingGET_TYPE = function() {
  return 'get'
}
export const triggerRestCheckUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-rest/check'
  if (parameters['method'] !== undefined) {
    queryParameters['method'] = parameters['method']
  }
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableTriggerMessagingUsingGET
 * url: enableTriggerMessagingUsingGETURL
 * method: enableTriggerMessagingUsingGET_TYPE
 * raw_url: enableTriggerMessagingUsingGET_RAW_URL
 * @param id - 
 */
export const enableTriggerMessagingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerMessagingUsingGET_RAW_URL = function() {
  return '/trigger-messaging/{id}/enable'
}
export const enableTriggerMessagingUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerMessagingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableTriggerMessagingUsingGET
 * url: disableTriggerMessagingUsingGETURL
 * method: disableTriggerMessagingUsingGET_TYPE
 * raw_url: disableTriggerMessagingUsingGET_RAW_URL
 * @param id - 
 */
export const disableTriggerMessagingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerMessagingUsingGET_RAW_URL = function() {
  return '/trigger-messaging/{id}/disable'
}
export const disableTriggerMessagingUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerMessagingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggerMessagingServicesUsingGET
 * url: getTriggerMessagingServicesUsingGETURL
 * method: getTriggerMessagingServicesUsingGET_TYPE
 * raw_url: getTriggerMessagingServicesUsingGET_RAW_URL
 */
export const getTriggerMessagingServicesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-messaging/services'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggerMessagingServicesUsingGET_RAW_URL = function() {
  return '/trigger-messaging/services'
}
export const getTriggerMessagingServicesUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggerMessagingServicesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-messaging/services'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableTriggerEventHandlerUsingGET
 * url: enableTriggerEventHandlerUsingGETURL
 * method: enableTriggerEventHandlerUsingGET_TYPE
 * raw_url: enableTriggerEventHandlerUsingGET_RAW_URL
 * @param id - 
 */
export const enableTriggerEventHandlerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerEventHandlerUsingGET_RAW_URL = function() {
  return '/trigger-event-handler/{id}/enable'
}
export const enableTriggerEventHandlerUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerEventHandlerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableTriggerEventHandlerUsingGET
 * url: disableTriggerEventHandlerUsingGETURL
 * method: disableTriggerEventHandlerUsingGET_TYPE
 * raw_url: disableTriggerEventHandlerUsingGET_RAW_URL
 * @param id - 
 */
export const disableTriggerEventHandlerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-event-handler/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerEventHandlerUsingGET_RAW_URL = function() {
  return '/trigger-event-handler/{id}/disable'
}
export const disableTriggerEventHandlerUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerEventHandlerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-event-handler/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: executeUsingGET
 * url: executeUsingGETURL
 * method: executeUsingGET_TYPE
 * raw_url: executeUsingGET_RAW_URL
 * @param id - 
 */
export const executeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}/execute'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const executeUsingGET_RAW_URL = function() {
  return '/trigger-cron/{id}/execute'
}
export const executeUsingGET_TYPE = function() {
  return 'get'
}
export const executeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}/execute'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableTriggerCronUsingGET
 * url: enableTriggerCronUsingGETURL
 * method: enableTriggerCronUsingGET_TYPE
 * raw_url: enableTriggerCronUsingGET_RAW_URL
 * @param id - 
 */
export const enableTriggerCronUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableTriggerCronUsingGET_RAW_URL = function() {
  return '/trigger-cron/{id}/enable'
}
export const enableTriggerCronUsingGET_TYPE = function() {
  return 'get'
}
export const enableTriggerCronUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableTriggerCronUsingGET
 * url: disableTriggerCronUsingGETURL
 * method: disableTriggerCronUsingGET_TYPE
 * raw_url: disableTriggerCronUsingGET_RAW_URL
 * @param id - 
 */
export const disableTriggerCronUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trigger-cron/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableTriggerCronUsingGET_RAW_URL = function() {
  return '/trigger-cron/{id}/disable'
}
export const disableTriggerCronUsingGET_TYPE = function() {
  return 'get'
}
export const disableTriggerCronUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trigger-cron/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTranslationUsingGET
 * url: getTranslationUsingGETURL
 * method: getTranslationUsingGET_TYPE
 * raw_url: getTranslationUsingGET_RAW_URL
 * @param id - 
 */
export const getTranslationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTranslationUsingGET_RAW_URL = function() {
  return '/translation/{id}'
}
export const getTranslationUsingGET_TYPE = function() {
  return 'get'
}
export const getTranslationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTelemetryYearlyUsingGET
 * url: getTelemetryYearlyUsingGETURL
 * method: getTelemetryYearlyUsingGET_TYPE
 * raw_url: getTelemetryYearlyUsingGET_RAW_URL
 * @param year - 
 * @param size - 
 * @param page - 
 */
export const getTelemetryYearlyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/telemetry/yearly'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['year'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: year'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryYearlyUsingGET_RAW_URL = function() {
  return '/telemetry/yearly'
}
export const getTelemetryYearlyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryYearlyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/telemetry/yearly'
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTelemetryMonthlyUsingGET
 * url: getTelemetryMonthlyUsingGETURL
 * method: getTelemetryMonthlyUsingGET_TYPE
 * raw_url: getTelemetryMonthlyUsingGET_RAW_URL
 * @param month - 
 * @param size - 
 * @param page - 
 */
export const getTelemetryMonthlyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/telemetry/monthly'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['month'] !== undefined) {
    queryParameters['month'] = parameters['month']
  }
  if (parameters['month'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: month'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryMonthlyUsingGET_RAW_URL = function() {
  return '/telemetry/monthly'
}
export const getTelemetryMonthlyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryMonthlyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/telemetry/monthly'
  if (parameters['month'] !== undefined) {
    queryParameters['month'] = parameters['month']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTelemetryDailyUsingGET
 * url: getTelemetryDailyUsingGETURL
 * method: getTelemetryDailyUsingGET_TYPE
 * raw_url: getTelemetryDailyUsingGET_RAW_URL
 * @param day - 
 * @param size - 
 * @param page - 
 */
export const getTelemetryDailyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/telemetry/daily'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['day'] !== undefined) {
    queryParameters['day'] = parameters['day']
  }
  if (parameters['day'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: day'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTelemetryDailyUsingGET_RAW_URL = function() {
  return '/telemetry/daily'
}
export const getTelemetryDailyUsingGET_TYPE = function() {
  return 'get'
}
export const getTelemetryDailyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/telemetry/daily'
  if (parameters['day'] !== undefined) {
    queryParameters['day'] = parameters['day']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getSettingsUsingGET
 * url: getSettingsUsingGETURL
 * method: getSettingsUsingGET_TYPE
 * raw_url: getSettingsUsingGET_RAW_URL
 * @param type - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSettingsUsingGET_RAW_URL = function() {
  return '/setting'
}
export const getSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getSettingByIdUsingGET
 * url: getSettingByIdUsingGETURL
 * method: getSettingByIdUsingGET_TYPE
 * raw_url: getSettingByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getSettingByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSettingByIdUsingGET_RAW_URL = function() {
  return '/setting/{id}'
}
export const getSettingByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getSettingByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteSettingUsingDELETE
 * url: deleteSettingUsingDELETEURL
 * method: deleteSettingUsingDELETE_TYPE
 * raw_url: deleteSettingUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteSettingUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSettingUsingDELETE_RAW_URL = function() {
  return '/setting/{id}'
}
export const deleteSettingUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteSettingUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableSettingUsingGET
 * url: enableSettingUsingGETURL
 * method: enableSettingUsingGET_TYPE
 * raw_url: enableSettingUsingGET_RAW_URL
 * @param id - 
 */
export const enableSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableSettingUsingGET_RAW_URL = function() {
  return '/setting/{id}/enable'
}
export const enableSettingUsingGET_TYPE = function() {
  return 'get'
}
export const enableSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableSettingUsingGET
 * url: disableSettingUsingGETURL
 * method: disableSettingUsingGET_TYPE
 * raw_url: disableSettingUsingGET_RAW_URL
 * @param id - 
 */
export const disableSettingUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/setting/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableSettingUsingGET_RAW_URL = function() {
  return '/setting/{id}/disable'
}
export const disableSettingUsingGET_TYPE = function() {
  return 'get'
}
export const disableSettingUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/setting/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getTriggersByProcessIdUsingGET
 * url: getTriggersByProcessIdUsingGETURL
 * method: getTriggersByProcessIdUsingGET_TYPE
 * raw_url: getTriggersByProcessIdUsingGET_RAW_URL
 * @param status - 
 * @param size - 
 * @param page - 
 * @param id - 
 */
export const getTriggersByProcessIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}/trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTriggersByProcessIdUsingGET_RAW_URL = function() {
  return '/process/{id}/trigger'
}
export const getTriggersByProcessIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTriggersByProcessIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}/trigger'
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessStatisticsUsingGET
 * url: getProcessStatisticsUsingGETURL
 * method: getProcessStatisticsUsingGET_TYPE
 * raw_url: getProcessStatisticsUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessStatisticsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}/statistics'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStatisticsUsingGET_RAW_URL = function() {
  return '/process/{id}/statistics'
}
export const getProcessStatisticsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStatisticsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}/statistics'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableProcessUsingGET
 * url: enableProcessUsingGETURL
 * method: enableProcessUsingGET_TYPE
 * raw_url: enableProcessUsingGET_RAW_URL
 * @param id - 
 */
export const enableProcessUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableProcessUsingGET_RAW_URL = function() {
  return '/process/{id}/enable'
}
export const enableProcessUsingGET_TYPE = function() {
  return 'get'
}
export const enableProcessUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableProcessUsingGET
 * url: disableProcessUsingGETURL
 * method: disableProcessUsingGET_TYPE
 * raw_url: disableProcessUsingGET_RAW_URL
 * @param id - 
 */
export const disableProcessUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableProcessUsingGET_RAW_URL = function() {
  return '/process/{id}/disable'
}
export const disableProcessUsingGET_TYPE = function() {
  return 'get'
}
export const disableProcessUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessStepLogsUsingGET
 * url: getProcessStepLogsUsingGETURL
 * method: getProcessStepLogsUsingGET_TYPE
 * raw_url: getProcessStepLogsUsingGET_RAW_URL
 * @param processId - 
 * @param eventId - 
 * @param stepType - 
 * @param stepId - 
 * @param status - 
 * @param dateFrom - 
 * @param dateTill - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getProcessStepLogsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-step-log'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['stepType'] !== undefined) {
    queryParameters['stepType'] = parameters['stepType']
  }
  if (parameters['stepId'] !== undefined) {
    queryParameters['stepId'] = parameters['stepId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepLogsUsingGET_RAW_URL = function() {
  return '/process-step-log'
}
export const getProcessStepLogsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepLogsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-step-log'
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['stepType'] !== undefined) {
    queryParameters['stepType'] = parameters['stepType']
  }
  if (parameters['stepId'] !== undefined) {
    queryParameters['stepId'] = parameters['stepId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessExceptionUsingGET
 * url: getProcessExceptionUsingGETURL
 * method: getProcessExceptionUsingGET_TYPE
 * raw_url: getProcessExceptionUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessExceptionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-step-log/{id}/exception'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessExceptionUsingGET_RAW_URL = function() {
  return '/process-step-log/{id}/exception'
}
export const getProcessExceptionUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessExceptionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-step-log/{id}/exception'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessStepLogDebugLogUsingGET
 * url: getProcessStepLogDebugLogUsingGETURL
 * method: getProcessStepLogDebugLogUsingGET_TYPE
 * raw_url: getProcessStepLogDebugLogUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessStepLogDebugLogUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-step-log/{id}/debug-log'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepLogDebugLogUsingGET_RAW_URL = function() {
  return '/process-step-log/{id}/debug-log'
}
export const getProcessStepLogDebugLogUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepLogDebugLogUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-step-log/{id}/debug-log'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessStepLogCacheUsingGET
 * url: getProcessStepLogCacheUsingGETURL
 * method: getProcessStepLogCacheUsingGET_TYPE
 * raw_url: getProcessStepLogCacheUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessStepLogCacheUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-step-log/{id}/cache'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepLogCacheUsingGET_RAW_URL = function() {
  return '/process-step-log/{id}/cache'
}
export const getProcessStepLogCacheUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepLogCacheUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-step-log/{id}/cache'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessStepFailuresUsingGET
 * url: getProcessStepFailuresUsingGETURL
 * method: getProcessStepFailuresUsingGET_TYPE
 * raw_url: getProcessStepFailuresUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 * @param size - 
 * @param page - 
 */
export const getProcessStepFailuresUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-step-log/failures'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateFrom'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateFrom'))
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['dateTill'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateTill'))
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessStepFailuresUsingGET_RAW_URL = function() {
  return '/process-step-log/failures'
}
export const getProcessStepFailuresUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessStepFailuresUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-step-log/failures'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessCredentialsUsingGET
 * url: getProcessCredentialsUsingGETURL
 * method: getProcessCredentialsUsingGET_TYPE
 * raw_url: getProcessCredentialsUsingGET_RAW_URL
 * @param type - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getProcessCredentialsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessCredentialsUsingGET_RAW_URL = function() {
  return '/process-credential'
}
export const getProcessCredentialsUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessCredentialsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessCredentialByIdUsingGET
 * url: getProcessCredentialByIdUsingGETURL
 * method: getProcessCredentialByIdUsingGET_TYPE
 * raw_url: getProcessCredentialByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getProcessCredentialByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessCredentialByIdUsingGET_RAW_URL = function() {
  return '/process-credential/{id}'
}
export const getProcessCredentialByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessCredentialByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteProcessCredentialUsingDELETE
 * url: deleteProcessCredentialUsingDELETEURL
 * method: deleteProcessCredentialUsingDELETE_TYPE
 * raw_url: deleteProcessCredentialUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteProcessCredentialUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteProcessCredentialUsingDELETE_RAW_URL = function() {
  return '/process-credential/{id}'
}
export const deleteProcessCredentialUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteProcessCredentialUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableProcessCredentialUsingGET
 * url: enableProcessCredentialUsingGETURL
 * method: enableProcessCredentialUsingGET_TYPE
 * raw_url: enableProcessCredentialUsingGET_RAW_URL
 * @param id - 
 */
export const enableProcessCredentialUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableProcessCredentialUsingGET_RAW_URL = function() {
  return '/process-credential/{id}/enable'
}
export const enableProcessCredentialUsingGET_TYPE = function() {
  return 'get'
}
export const enableProcessCredentialUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableProcessCredentialUsingGET
 * url: disableProcessCredentialUsingGETURL
 * method: disableProcessCredentialUsingGET_TYPE
 * raw_url: disableProcessCredentialUsingGET_RAW_URL
 * @param id - 
 */
export const disableProcessCredentialUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/process-credential/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableProcessCredentialUsingGET_RAW_URL = function() {
  return '/process-credential/{id}/disable'
}
export const disableProcessCredentialUsingGET_TYPE = function() {
  return 'get'
}
export const disableProcessCredentialUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/process-credential/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: downloadPluginJarUsingGET
 * url: downloadPluginJarUsingGETURL
 * method: downloadPluginJarUsingGET_TYPE
 * raw_url: downloadPluginJarUsingGET_RAW_URL
 * @param id - 
 */
export const downloadPluginJarUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}/jar'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadPluginJarUsingGET_RAW_URL = function() {
  return '/plugin/{id}/jar'
}
export const downloadPluginJarUsingGET_TYPE = function() {
  return 'get'
}
export const downloadPluginJarUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}/jar'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enablePluginUsingGET
 * url: enablePluginUsingGETURL
 * method: enablePluginUsingGET_TYPE
 * raw_url: enablePluginUsingGET_RAW_URL
 * @param id - 
 */
export const enablePluginUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enablePluginUsingGET_RAW_URL = function() {
  return '/plugin/{id}/enable'
}
export const enablePluginUsingGET_TYPE = function() {
  return 'get'
}
export const enablePluginUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disablePluginUsingGET
 * url: disablePluginUsingGETURL
 * method: disablePluginUsingGET_TYPE
 * raw_url: disablePluginUsingGET_RAW_URL
 * @param id - 
 */
export const disablePluginUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plugin/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disablePluginUsingGET_RAW_URL = function() {
  return '/plugin/{id}/disable'
}
export const disablePluginUsingGET_TYPE = function() {
  return 'get'
}
export const disablePluginUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plugin/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: swaggerUsingGET
 * url: swaggerUsingGETURL
 * method: swaggerUsingGET_TYPE
 * raw_url: swaggerUsingGET_RAW_URL
 * @param id - 
 */
export const swaggerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}/swagger'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const swaggerUsingGET_RAW_URL = function() {
  return '/module/{id}/swagger'
}
export const swaggerUsingGET_TYPE = function() {
  return 'get'
}
export const swaggerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}/swagger'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: exportUsingGET
 * url: exportUsingGETURL
 * method: exportUsingGET_TYPE
 * raw_url: exportUsingGET_RAW_URL
 * @param id - 
 */
export const exportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/module/{id}/export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const exportUsingGET_RAW_URL = function() {
  return '/module/{id}/export'
}
export const exportUsingGET_TYPE = function() {
  return 'get'
}
export const exportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/module/{id}/export'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: logoutUsingGET
 * url: logoutUsingGETURL
 * method: logoutUsingGET_TYPE
 * raw_url: logoutUsingGET_RAW_URL
 */
export const logoutUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/logout'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const logoutUsingGET_RAW_URL = function() {
  return '/logout'
}
export const logoutUsingGET_TYPE = function() {
  return 'get'
}
export const logoutUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/logout'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableLibraryUsingGET
 * url: enableLibraryUsingGETURL
 * method: enableLibraryUsingGET_TYPE
 * raw_url: enableLibraryUsingGET_RAW_URL
 * @param id - 
 */
export const enableLibraryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableLibraryUsingGET_RAW_URL = function() {
  return '/library/{id}/enable'
}
export const enableLibraryUsingGET_TYPE = function() {
  return 'get'
}
export const enableLibraryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: downloadLibraryUsingGET
 * url: downloadLibraryUsingGETURL
 * method: downloadLibraryUsingGET_TYPE
 * raw_url: downloadLibraryUsingGET_RAW_URL
 * @param id - 
 */
export const downloadLibraryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}/download'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadLibraryUsingGET_RAW_URL = function() {
  return '/library/{id}/download'
}
export const downloadLibraryUsingGET_TYPE = function() {
  return 'get'
}
export const downloadLibraryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}/download'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableLibraryUsingGET
 * url: disableLibraryUsingGETURL
 * method: disableLibraryUsingGET_TYPE
 * raw_url: disableLibraryUsingGET_RAW_URL
 * @param id - 
 */
export const disableLibraryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/library/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableLibraryUsingGET_RAW_URL = function() {
  return '/library/{id}/disable'
}
export const disableLibraryUsingGET_TYPE = function() {
  return 'get'
}
export const disableLibraryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/library/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getInternalJobsUsingGET
 * url: getInternalJobsUsingGETURL
 * method: getInternalJobsUsingGET_TYPE
 * raw_url: getInternalJobsUsingGET_RAW_URL
 * @param instanceId - 
 * @param type - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getInternalJobsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/internal-job'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['instanceId'] !== undefined) {
    queryParameters['instanceId'] = parameters['instanceId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInternalJobsUsingGET_RAW_URL = function() {
  return '/internal-job'
}
export const getInternalJobsUsingGET_TYPE = function() {
  return 'get'
}
export const getInternalJobsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/internal-job'
  if (parameters['instanceId'] !== undefined) {
    queryParameters['instanceId'] = parameters['instanceId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getInternalJobByIdUsingGET
 * url: getInternalJobByIdUsingGETURL
 * method: getInternalJobByIdUsingGET_TYPE
 * raw_url: getInternalJobByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getInternalJobByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/internal-job/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInternalJobByIdUsingGET_RAW_URL = function() {
  return '/internal-job/{id}'
}
export const getInternalJobByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getInternalJobByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/internal-job/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getInstancesUsingGET
 * url: getInstancesUsingGETURL
 * method: getInstancesUsingGET_TYPE
 * raw_url: getInstancesUsingGET_RAW_URL
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getInstancesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/instance'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInstancesUsingGET_RAW_URL = function() {
  return '/instance'
}
export const getInstancesUsingGET_TYPE = function() {
  return 'get'
}
export const getInstancesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/instance'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getInstanceByIdUsingGET
 * url: getInstanceByIdUsingGETURL
 * method: getInstanceByIdUsingGET_TYPE
 * raw_url: getInstanceByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getInstanceByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/instance/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInstanceByIdUsingGET_RAW_URL = function() {
  return '/instance/{id}'
}
export const getInstanceByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getInstanceByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/instance/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubUploadModuleUsingGET
 * url: hubUploadModuleUsingGETURL
 * method: hubUploadModuleUsingGET_TYPE
 * raw_url: hubUploadModuleUsingGET_RAW_URL
 * @param moduleId - 
 */
export const hubUploadModuleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/upload-module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubUploadModuleUsingGET_RAW_URL = function() {
  return '/hub/upload-module/{moduleId}'
}
export const hubUploadModuleUsingGET_TYPE = function() {
  return 'get'
}
export const hubUploadModuleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/upload-module/{moduleId}'
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubModuleUpdateUsingGET
 * url: hubModuleUpdateUsingGETURL
 * method: hubModuleUpdateUsingGET_TYPE
 * raw_url: hubModuleUpdateUsingGET_RAW_URL
 * @param moduleId - 
 */
export const hubModuleUpdateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/update-module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleUpdateUsingGET_RAW_URL = function() {
  return '/hub/update-module/{moduleId}'
}
export const hubModuleUpdateUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleUpdateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/update-module/{moduleId}'
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubModuleSearchUsingGET
 * url: hubModuleSearchUsingGETURL
 * method: hubModuleSearchUsingGET_TYPE
 * raw_url: hubModuleSearchUsingGET_RAW_URL
 * @param credentialId - 
 * @param name - 
 * @param size - 
 * @param page - 
 */
export const hubModuleSearchUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/modules'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleSearchUsingGET_RAW_URL = function() {
  return '/hub/modules'
}
export const hubModuleSearchUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleSearchUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/modules'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubGetModuleUsingGET
 * url: hubGetModuleUsingGETURL
 * method: hubGetModuleUsingGET_TYPE
 * raw_url: hubGetModuleUsingGET_RAW_URL
 * @param moduleId - 
 * @param credentialId - 
 */
export const hubGetModuleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/module/{moduleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['moduleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: moduleId'))
  }
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubGetModuleUsingGET_RAW_URL = function() {
  return '/hub/module/{moduleId}'
}
export const hubGetModuleUsingGET_TYPE = function() {
  return 'get'
}
export const hubGetModuleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/module/{moduleId}'
  path = path.replace('{moduleId}', `${parameters['moduleId']}`)
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: hubModuleDownloadUsingGET
 * url: hubModuleDownloadUsingGETURL
 * method: hubModuleDownloadUsingGET_TYPE
 * raw_url: hubModuleDownloadUsingGET_RAW_URL
 * @param remoteModuleId - 
 * @param credentialId - 
 */
export const hubModuleDownloadUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/hub/download-module/{remoteModuleId}/credential/{credentialId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{remoteModuleId}', `${parameters['remoteModuleId']}`)
  if (parameters['remoteModuleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remoteModuleId'))
  }
  path = path.replace('{credentialId}', `${parameters['credentialId']}`)
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const hubModuleDownloadUsingGET_RAW_URL = function() {
  return '/hub/download-module/{remoteModuleId}/credential/{credentialId}'
}
export const hubModuleDownloadUsingGET_TYPE = function() {
  return 'get'
}
export const hubModuleDownloadUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/hub/download-module/{remoteModuleId}/credential/{credentialId}'
  path = path.replace('{remoteModuleId}', `${parameters['remoteModuleId']}`)
  path = path.replace('{credentialId}', `${parameters['credentialId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHistoriesUsingGET
 * url: getHistoriesUsingGETURL
 * method: getHistoriesUsingGET_TYPE
 * raw_url: getHistoriesUsingGET_RAW_URL
 * @param resourceId - 
 * @param resourceType - 
 * @param size - 
 * @param page - 
 */
export const getHistoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['resourceId'] !== undefined) {
    queryParameters['resourceId'] = parameters['resourceId']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHistoriesUsingGET_RAW_URL = function() {
  return '/history'
}
export const getHistoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getHistoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/history'
  if (parameters['resourceId'] !== undefined) {
    queryParameters['resourceId'] = parameters['resourceId']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getHistoryByIdUsingGET
 * url: getHistoryByIdUsingGETURL
 * method: getHistoryByIdUsingGET_TYPE
 * raw_url: getHistoryByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getHistoryByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/history/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getHistoryByIdUsingGET_RAW_URL = function() {
  return '/history/{id}'
}
export const getHistoryByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getHistoryByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/history/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getDeleteHistoriesUsingGET
 * url: getDeleteHistoriesUsingGETURL
 * method: getDeleteHistoriesUsingGET_TYPE
 * raw_url: getDeleteHistoriesUsingGET_RAW_URL
 * @param resourceName - 
 * @param resourceType - 
 * @param dateFrom - 
 * @param dateTill - 
 * @param size - 
 * @param page - 
 */
export const getDeleteHistoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/history/deletion'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['resourceName'] !== undefined) {
    queryParameters['resourceName'] = parameters['resourceName']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeleteHistoriesUsingGET_RAW_URL = function() {
  return '/history/deletion'
}
export const getDeleteHistoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getDeleteHistoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/history/deletion'
  if (parameters['resourceName'] !== undefined) {
    queryParameters['resourceName'] = parameters['resourceName']
  }
  if (parameters['resourceType'] !== undefined) {
    queryParameters['resourceType'] = parameters['resourceType']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getGlobalSettingsUsingGET
 * url: getGlobalSettingsUsingGETURL
 * method: getGlobalSettingsUsingGET_TYPE
 * raw_url: getGlobalSettingsUsingGET_RAW_URL
 * @param type - 
 */
export const getGlobalSettingsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getGlobalSettingsUsingGET_RAW_URL = function() {
  return '/global-setting'
}
export const getGlobalSettingsUsingGET_TYPE = function() {
  return 'get'
}
export const getGlobalSettingsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getGlobalSettingByIdUsingGET
 * url: getGlobalSettingByIdUsingGETURL
 * method: getGlobalSettingByIdUsingGET_TYPE
 * raw_url: getGlobalSettingByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getGlobalSettingByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getGlobalSettingByIdUsingGET_RAW_URL = function() {
  return '/global-setting/{id}'
}
export const getGlobalSettingByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getGlobalSettingByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getGlobalSettingBasicUsingGET
 * url: getGlobalSettingBasicUsingGETURL
 * method: getGlobalSettingBasicUsingGET_TYPE
 * raw_url: getGlobalSettingBasicUsingGET_RAW_URL
 */
export const getGlobalSettingBasicUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/global-setting/basic'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getGlobalSettingBasicUsingGET_RAW_URL = function() {
  return '/global-setting/basic'
}
export const getGlobalSettingBasicUsingGET_TYPE = function() {
  return 'get'
}
export const getGlobalSettingBasicUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/global-setting/basic'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: restTriggersGdprReportUsingGET
 * url: restTriggersGdprReportUsingGETURL
 * method: restTriggersGdprReportUsingGET_TYPE
 * raw_url: restTriggersGdprReportUsingGET_RAW_URL
 */
export const restTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/rest-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const restTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/rest-trigger'
}
export const restTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const restTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/rest-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: processesGdprReportUsingGET
 * url: processesGdprReportUsingGETURL
 * method: processesGdprReportUsingGET_TYPE
 * raw_url: processesGdprReportUsingGET_RAW_URL
 */
export const processesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/processes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const processesGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/processes'
}
export const processesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const processesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/processes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: processCredentialsGdprReportUsingGET
 * url: processCredentialsGdprReportUsingGETURL
 * method: processCredentialsGdprReportUsingGET_TYPE
 * raw_url: processCredentialsGdprReportUsingGET_RAW_URL
 */
export const processCredentialsGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/process-credentials'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const processCredentialsGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/process-credentials'
}
export const processCredentialsGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const processCredentialsGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/process-credentials'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: modulesGdprReportUsingGET
 * url: modulesGdprReportUsingGETURL
 * method: modulesGdprReportUsingGET_TYPE
 * raw_url: modulesGdprReportUsingGET_RAW_URL
 */
export const modulesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/modules'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const modulesGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/modules'
}
export const modulesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const modulesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/modules'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: messagingTriggersGdprReportUsingGET
 * url: messagingTriggersGdprReportUsingGETURL
 * method: messagingTriggersGdprReportUsingGET_TYPE
 * raw_url: messagingTriggersGdprReportUsingGET_RAW_URL
 */
export const messagingTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/messaging-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const messagingTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/messaging-trigger'
}
export const messagingTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const messagingTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/messaging-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: librariesGdprReportUsingGET
 * url: librariesGdprReportUsingGETURL
 * method: librariesGdprReportUsingGET_TYPE
 * raw_url: librariesGdprReportUsingGET_RAW_URL
 */
export const librariesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/libraries'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const librariesGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/libraries'
}
export const librariesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const librariesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/libraries'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entitiesGdprReportUsingGET
 * url: entitiesGdprReportUsingGETURL
 * method: entitiesGdprReportUsingGET_TYPE
 * raw_url: entitiesGdprReportUsingGET_RAW_URL
 */
export const entitiesGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/entities'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const entitiesGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/entities'
}
export const entitiesGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const entitiesGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/entities'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: cronTriggersGdprReportUsingGET
 * url: cronTriggersGdprReportUsingGETURL
 * method: cronTriggersGdprReportUsingGET_TYPE
 * raw_url: cronTriggersGdprReportUsingGET_RAW_URL
 */
export const cronTriggersGdprReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/gdpr/cron-trigger'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cronTriggersGdprReportUsingGET_RAW_URL = function() {
  return '/gdpr/cron-trigger'
}
export const cronTriggersGdprReportUsingGET_TYPE = function() {
  return 'get'
}
export const cronTriggersGdprReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/gdpr/cron-trigger'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEventsUsingGET
 * url: getEventsUsingGETURL
 * method: getEventsUsingGET_TYPE
 * raw_url: getEventsUsingGET_RAW_URL
 * @param triggerId - 
 * @param processId - 
 * @param type - 
 * @param status - 
 * @param dateFrom - 
 * @param dateTill - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getEventsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['triggerId'] !== undefined) {
    queryParameters['triggerId'] = parameters['triggerId']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventsUsingGET_RAW_URL = function() {
  return '/event'
}
export const getEventsUsingGET_TYPE = function() {
  return 'get'
}
export const getEventsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event'
  if (parameters['triggerId'] !== undefined) {
    queryParameters['triggerId'] = parameters['triggerId']
  }
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEventByIdUsingGET
 * url: getEventByIdUsingGETURL
 * method: getEventByIdUsingGET_TYPE
 * raw_url: getEventByIdUsingGET_RAW_URL
 * @param id - 
 */
export const getEventByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventByIdUsingGET_RAW_URL = function() {
  return '/event/{id}'
}
export const getEventByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getEventByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEventsWithProcessesUsingGET
 * url: getEventsWithProcessesUsingGETURL
 * method: getEventsWithProcessesUsingGET_TYPE
 * raw_url: getEventsWithProcessesUsingGET_RAW_URL
 * @param processId - 
 * @param status - 
 * @param dateFrom - 
 * @param dateTill - 
 * @param size - 
 * @param page - 
 */
export const getEventsWithProcessesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/with-processes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventsWithProcessesUsingGET_RAW_URL = function() {
  return '/event/with-processes'
}
export const getEventsWithProcessesUsingGET_TYPE = function() {
  return 'get'
}
export const getEventsWithProcessesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/with-processes'
  if (parameters['processId'] !== undefined) {
    queryParameters['processId'] = parameters['processId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEventStatusStatisticsUsingGET
 * url: getEventStatusStatisticsUsingGETURL
 * method: getEventStatusStatisticsUsingGET_TYPE
 * raw_url: getEventStatusStatisticsUsingGET_RAW_URL
 * @param dateFrom - 
 * @param dateTill - 
 */
export const getEventStatusStatisticsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/status-statistics'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventStatusStatisticsUsingGET_RAW_URL = function() {
  return '/event/status-statistics'
}
export const getEventStatusStatisticsUsingGET_TYPE = function() {
  return 'get'
}
export const getEventStatusStatisticsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/status-statistics'
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getProcessesNamesUsingGET
 * url: getProcessesNamesUsingGETURL
 * method: getProcessesNamesUsingGET_TYPE
 * raw_url: getProcessesNamesUsingGET_RAW_URL
 * @param processName - 
 * @param status - 
 * @param dateFrom - 
 * @param dateTill - 
 * @param size - 
 * @param page - 
 */
export const getProcessesNamesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/processes-names'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['processName'] !== undefined) {
    queryParameters['processName'] = parameters['processName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProcessesNamesUsingGET_RAW_URL = function() {
  return '/event/processes-names'
}
export const getProcessesNamesUsingGET_TYPE = function() {
  return 'get'
}
export const getProcessesNamesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/processes-names'
  if (parameters['processName'] !== undefined) {
    queryParameters['processName'] = parameters['processName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['dateFrom'] !== undefined) {
    queryParameters['dateFrom'] = parameters['dateFrom']
  }
  if (parameters['dateTill'] !== undefined) {
    queryParameters['dateTill'] = parameters['dateTill']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getMostRecentEventIdUsingGET
 * url: getMostRecentEventIdUsingGETURL
 * method: getMostRecentEventIdUsingGET_TYPE
 * raw_url: getMostRecentEventIdUsingGET_RAW_URL
 * @param processId - 
 */
export const getMostRecentEventIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/most-recent/{processId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters['processId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: processId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMostRecentEventIdUsingGET_RAW_URL = function() {
  return '/event/most-recent/{processId}'
}
export const getMostRecentEventIdUsingGET_TYPE = function() {
  return 'get'
}
export const getMostRecentEventIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/most-recent/{processId}'
  path = path.replace('{processId}', `${parameters['processId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEntityResourcesUsingGET
 * url: getEntityResourcesUsingGETURL
 * method: getEntityResourcesUsingGET_TYPE
 * raw_url: getEntityResourcesUsingGET_RAW_URL
 * @param id - 
 */
export const getEntityResourcesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/resources'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEntityResourcesUsingGET_RAW_URL = function() {
  return '/entity/{id}/resources'
}
export const getEntityResourcesUsingGET_TYPE = function() {
  return 'get'
}
export const getEntityResourcesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/resources'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteEntityResourcesUsingDELETE
 * url: deleteEntityResourcesUsingDELETEURL
 * method: deleteEntityResourcesUsingDELETE_TYPE
 * raw_url: deleteEntityResourcesUsingDELETE_RAW_URL
 * @param id - 
 * @param force - 
 */
export const deleteEntityResourcesUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/resources'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters['force'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: force'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEntityResourcesUsingDELETE_RAW_URL = function() {
  return '/entity/{id}/resources'
}
export const deleteEntityResourcesUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEntityResourcesUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/resources'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['force'] !== undefined) {
    queryParameters['force'] = parameters['force']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityPersistenceValidateUsingGET
 * url: entityPersistenceValidateUsingGETURL
 * method: entityPersistenceValidateUsingGET_TYPE
 * raw_url: entityPersistenceValidateUsingGET_RAW_URL
 * @param id - 
 */
export const entityPersistenceValidateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/persistence/validate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const entityPersistenceValidateUsingGET_RAW_URL = function() {
  return '/entity/{id}/persistence/validate'
}
export const entityPersistenceValidateUsingGET_TYPE = function() {
  return 'get'
}
export const entityPersistenceValidateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/persistence/validate'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityPersistenceValidateUpdateUsingGET
 * url: entityPersistenceValidateUpdateUsingGETURL
 * method: entityPersistenceValidateUpdateUsingGET_TYPE
 * raw_url: entityPersistenceValidateUpdateUsingGET_RAW_URL
 * @param id - 
 */
export const entityPersistenceValidateUpdateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/persistence/update/validate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const entityPersistenceValidateUpdateUsingGET_RAW_URL = function() {
  return '/entity/{id}/persistence/update/validate'
}
export const entityPersistenceValidateUpdateUsingGET_TYPE = function() {
  return 'get'
}
export const entityPersistenceValidateUpdateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/persistence/update/validate'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: enableEntityUsingGET
 * url: enableEntityUsingGETURL
 * method: enableEntityUsingGET_TYPE
 * raw_url: enableEntityUsingGET_RAW_URL
 * @param id - 
 */
export const enableEntityUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const enableEntityUsingGET_RAW_URL = function() {
  return '/entity/{id}/enable'
}
export const enableEntityUsingGET_TYPE = function() {
  return 'get'
}
export const enableEntityUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: disableEntityUsingGET
 * url: disableEntityUsingGETURL
 * method: disableEntityUsingGET_TYPE
 * raw_url: disableEntityUsingGET_RAW_URL
 * @param id - 
 */
export const disableEntityUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disableEntityUsingGET_RAW_URL = function() {
  return '/entity/{id}/disable'
}
export const disableEntityUsingGET_TYPE = function() {
  return 'get'
}
export const disableEntityUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEntitiesWithSystemPermissionRolesUsingGET
 * url: getEntitiesWithSystemPermissionRolesUsingGETURL
 * method: getEntitiesWithSystemPermissionRolesUsingGET_TYPE
 * raw_url: getEntitiesWithSystemPermissionRolesUsingGET_RAW_URL
 * @param permissionType - 
 * @param status - 
 * @param name - 
 * @param orderField - 
 * @param orderDirection - 
 * @param size - 
 * @param page - 
 */
export const getEntitiesWithSystemPermissionRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/system-permission-roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEntitiesWithSystemPermissionRolesUsingGET_RAW_URL = function() {
  return '/entity/system-permission-roles'
}
export const getEntitiesWithSystemPermissionRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getEntitiesWithSystemPermissionRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/system-permission-roles'
  if (parameters['permissionType'] !== undefined) {
    queryParameters['permissionType'] = parameters['permissionType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['orderField'] !== undefined) {
    queryParameters['orderField'] = parameters['orderField']
  }
  if (parameters['orderDirection'] !== undefined) {
    queryParameters['orderDirection'] = parameters['orderDirection']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: validateEntityFromPersistenceUsingGET
 * url: validateEntityFromPersistenceUsingGETURL
 * method: validateEntityFromPersistenceUsingGET_TYPE
 * raw_url: validateEntityFromPersistenceUsingGET_RAW_URL
 * @param credentialId - 
 * @param persistenceName - 
 */
export const validateEntityFromPersistenceUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/from-persistence/validate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['credentialId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentialId'))
  }
  if (parameters['persistenceName'] !== undefined) {
    queryParameters['persistenceName'] = parameters['persistenceName']
  }
  if (parameters['persistenceName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: persistenceName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const validateEntityFromPersistenceUsingGET_RAW_URL = function() {
  return '/entity/from-persistence/validate'
}
export const validateEntityFromPersistenceUsingGET_TYPE = function() {
  return 'get'
}
export const validateEntityFromPersistenceUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/from-persistence/validate'
  if (parameters['credentialId'] !== undefined) {
    queryParameters['credentialId'] = parameters['credentialId']
  }
  if (parameters['persistenceName'] !== undefined) {
    queryParameters['persistenceName'] = parameters['persistenceName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: getEncodingTypesUsingGET
 * url: getEncodingTypesUsingGETURL
 * method: getEncodingTypesUsingGET_TYPE
 * raw_url: getEncodingTypesUsingGET_RAW_URL
 */
export const getEncodingTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/encoding-type'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEncodingTypesUsingGET_RAW_URL = function() {
  return '/encoding-type'
}
export const getEncodingTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getEncodingTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/encoding-type'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: captchaCreateUsingGET
 * url: captchaCreateUsingGETURL
 * method: captchaCreateUsingGET_TYPE
 * raw_url: captchaCreateUsingGET_RAW_URL
 */
export const captchaCreateUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/captcha/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const captchaCreateUsingGET_RAW_URL = function() {
  return '/captcha/create'
}
export const captchaCreateUsingGET_TYPE = function() {
  return 'get'
}
export const captchaCreateUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/captcha/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: twoFactorAuthDisableByUserIdUsingDELETE
 * url: twoFactorAuthDisableByUserIdUsingDELETEURL
 * method: twoFactorAuthDisableByUserIdUsingDELETE_TYPE
 * raw_url: twoFactorAuthDisableByUserIdUsingDELETE_RAW_URL
 * @param id - 
 */
export const twoFactorAuthDisableByUserIdUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/two-factor-auth'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const twoFactorAuthDisableByUserIdUsingDELETE_RAW_URL = function() {
  return '/user/{id}/two-factor-auth'
}
export const twoFactorAuthDisableByUserIdUsingDELETE_TYPE = function() {
  return 'delete'
}
export const twoFactorAuthDisableByUserIdUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/two-factor-auth'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: deleteUserSessionsUsingDELETE
 * url: deleteUserSessionsUsingDELETEURL
 * method: deleteUserSessionsUsingDELETE_TYPE
 * raw_url: deleteUserSessionsUsingDELETE_RAW_URL
 * @param id - 
 */
export const deleteUserSessionsUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/sessions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteUserSessionsUsingDELETE_RAW_URL = function() {
  return '/user/{id}/sessions'
}
export const deleteUserSessionsUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteUserSessionsUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/sessions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: entityPersistenceDeleteUsingDELETE
 * url: entityPersistenceDeleteUsingDELETEURL
 * method: entityPersistenceDeleteUsingDELETE_TYPE
 * raw_url: entityPersistenceDeleteUsingDELETE_RAW_URL
 * @param id - 
 */
export const entityPersistenceDeleteUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/entity/{id}/persistence'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const entityPersistenceDeleteUsingDELETE_RAW_URL = function() {
  return '/entity/{id}/persistence'
}
export const entityPersistenceDeleteUsingDELETE_TYPE = function() {
  return 'delete'
}
export const entityPersistenceDeleteUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/entity/{id}/persistence'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}